import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Button, TextField, InputAdornment, ClickAwayListener } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import NormalDialog from "../styled/CommonComponents/NormalDialog"
import SearchIcon from '@material-ui/icons/Search';
import { getItemsByFilter } from "./api.call";
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import DeleteIcon from '@material-ui/icons/Delete';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import WorkitemResourceInputTable from "./WokrItemResourceInputTable";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";
import { VscInbox } from "react-icons/vsc";
import { GrPowerReset } from "react-icons/gr";
import "./index.css"

const itemTypesArr = ["Material", "Material + Labour", "Labour", "Machinery & Equipments", "Services", "Subcontract"]
const itemTypesForResourcesArr = ["Material", "Material + Labour", "Labour", "Machinery & Equipments", "Contractor Add-Ons"]
const itemTypesForResourcesValArr = ["material", "materialAndLabour", "labour", "machineryAndEquipments", "contractorAddOns"]
const keyValuePair = { material: "Material", materialAndLabour: "Material + Labour", labour: "Labour", machineryAndEquipments: "Machinery & Equipments", contractorAddOns: "Contractor Add-Ons" }
const alp = ["A", "B", "C", "D", "E"]
const rateType = ["Direct Rate", "Unit Rate Analysis"]
const adOnTypesArr = ["Tax", "Other"]

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    sideBar: {
        width: "400px",
        height: "100%",
        borderRight: "1px solid #c5c4c4",
        padding: "0px 10px",
    },
    titleSty: {
        fontSize: "16px",
        fontWeight: "510",
        marginBottom: "10px"
    },
    textLable: {
        fontSize: "14px",
        fontWeight: "500",
        margin: "10px 0px 2px"
    },
    itemDetailsAndBtnCont: {
        width: "calc(100% - 405px)",
        height: "100%",
        padding: "0px 10px",
    },
    itemDetailsCont: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        paddingBottom: "50px",
        paddingTop: "10px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    titleAndBtnCont: {
        width: "100%",
        height: "50px",
        marginBottom: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    progressCont: {
        marginTop: "5px",
        width: "100%",
        height: "5px"
    },
    itemsCont: {
        width: "100%",
        height: "calc(100% - 310px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    singleItemCont: {
        width: "100%",
        padding: "5px",
        fontSize: "13px",
        fontWeight: "400",
        color: "gray",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    categorySubcategoryCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "30px"
    },
    categoryCont: {
        width: "49%",
        position: "relative",
    },
    subcategoryCont: {
        width: "49%",
        position: "relative",
    },
    suggestionCont: {
        position: "absolute",
        top: "43px",
        left: "0px",
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
        zIndex: "9",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
    },
    nameRateTypeItemTypeCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "30px"
    },
    itemNameCont: {
        width: "49%"
    },
    rateTypeCont: {
        width: "24%"
    },
    itemTypeCont: {
        width: "24%"
    },
    singleSuggetion: {
        width: "100%",
        padding: "5px",
        fontSize: "13px",
        fontWeight: "400",
        color: "gray",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    workItemTitle: {
        fontSize: "25px",
        fontWeight: "600",
    },
    qtyUnitRateCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px"
    },
    qtyCont: {
        width: "200px",
        marginRight: "10px"
    },
    unitCont: {
        width: "200px",
        marginRight: "10px"
    },
    rateCont: {
        width: "200px",
        marginRight: "10px"
    },
    tableSty: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        marginTop: "40px",
        tableLayout: "auto",
        "& tr": {
            height: "35px"
        },
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right"
        }
    },
    resourcesTableSty: {

    },
    qtyTableSty: {
        width: "75px"
    },
    qtyWastageTableSty: {
        width: "90px"
    },
    qtyTotalTableSty: {
        width: "110px"
    },
    unitTableSty: {
        width: "100px"
    },
    rateTableSty: {
        width: "95px"
    },
    taxTableSty: {
        width: "75px"
    },
    rateTaxTableSty: {
        width: "120px"
    },
    totalTableSty: {
        width: "140px"
    },
    lableBtnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "space-between",
        "& p": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& .MuiButton-root": {
            minWidth: "50px"
        },
        "& .MuiButton-label": {
            fontSize: "10px",
            color: "white",
        },
        "& .MuiButton-root:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    btnCont: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        padding: "4px",
        borderRadius: "10px",
        marginLeft: "10px"
    },
    typeSingleOptionSty: {
        padding: "5px 7px",
        fontSize: "15px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    optionsCont: {
        padding: "5px"
    },
    numberInputSty: {
        border: "none",
        textAlign: "right"
    },
    textInputSty: {
        border: "none",
        fontSize: "15px",
        fontWeight: "500",
        paddingLeft: "5px"
    },
    errorMsg: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        textAlign: "left",
        paddingTop: "0px",
        height: "5px"
    },
    tableErrMsg: {
        padding: "0px",
        margin: "0px",
        fontSize: "10px",
        fontWeight: "400",
        color: "red",
        height: "12px",
        textAlign: "left",
        paddingLeft: "7px"
    },
    subheaderCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiIconButton-root": {
            padding: "0px",
            marginLeft: "10px"
        }
    },
    totalDeleteCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& p": {
            marginRight: "3px"
        }
    },
    OptionTab: {
        width: "100%",
        height: "20px",
        position: "absolute",
        top: "0px",
        left: "0px"
    },
    emptySty: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    }
}));

const BudgetCostAddWorkItem = ({
    createWorkItem, openItemAddDialog, setOpenItemAddDialog,
    libraryCategoryDictionary, setLibraryCategoryDictionary,
    projectProfileId, curWorkItem
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    //side bar useStates
    const [searchText, setSearchText] = useState("")
    const [selectedLibrary, setSelectedLibrary] = useState("")
    const [categoriesArr, setCategoriesArr] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [subcategoriesArr, setSubategoriesArr] = useState([])
    const [selectedSubcategory, setSelectedSubcategory] = useState(null)
    const [resultItemsArr, setResultItemsArr] = useState([])
    const [resultItemsArrLoader, setResultItemsArrLoader] = useState(false)

    //#region
    const [openCategorySuggestion, setOpenCategorySuggestion] = useState(false)
    const [openSubcategorySuggestion, setOpenSubcategorySuggestion] = useState(false)
    const [suggestedCategories, setSuggestedCategories] = useState([])
    const [suggestedSubcategories, setSuggestedSubcategories] = useState([])
    const [category, setCategory] = useState("")
    const [categoryObj, setCategoryObj] = useState(null)
    const [subcategory, setSubcategory] = useState("")
    const [subcategoryObj, setSubcategoryObj] = useState(null)
    const [itemName, setItemName] = useState("")
    const [rateTypeValue, setRateTypeValue] = useState("Direct Rate")
    const [itemTypeValue, setItemTypeValue] = useState("Material + Labour")
    const [forQty, setForQty] = useState(1)
    const [unitValue, setUnitValue] = useState(null)
    const [allOverTaxInParcentage, setAllOverTaxInParcentage] = useState(0)
    const [allOverRateIncludingTax, setAllOverRateIncludingTax] = useState(0)
    const [materialArrObj, setMaterialArrObj] = useState([])
    const [materialAndLabourArrObj, setMaterialAndLabourArrObj] = useState([])
    const [labourArrObj, setLabourArrObj] = useState([])
    const [machineryAndEquipmentsArrObj, setMachineryAndEquipmentsArrObj] = useState([])
    const [contractorAddOnsArr, setContractorAddOnsArr] = useState([])
    const [allItemsDetailsObjArr, setAllItemsDetailsObjArr] = useState([])
    const [totalOfAllItems, setTotalOfAllItems] = useState(0);
    const [totalAmountWithoutAdOn, setTotalAmountWithoutAdOn] = useState(0);
    const [totalTaxAmount, setTotalTaxAmount] = useState(0);
    const [note, setNote] = useState("");
    const [selectedSuggestedWorkItem, setSelectedSuggestedWorkItem] = useState(null)
    const [totalOfAllItemsAlphabet, setTotalOfAllItemsAlphabet] = useState([]);
    const [totalAmountWithoutAdOnAlphabet, setTotalAmountWithoutAdOnAlphabet] = useState([]);
    const [showErrors, setShowErrors] = useState(false)
    const [addLoader, setAddLoader] = useState(false)
    const [getFilteredWorkitemChagne, setGetFilteredWorkitemChagne] = useState(false)
    //#endregion

    //#region To set initial library and categories
    useEffect(() => {
        if (libraryCategoryDictionary !== null && libraryCategoryDictionary && libraryCategoryDictionary.length > 0) {
            let locSelectedLibrary = libraryCategoryDictionary[0];
            setSelectedLibrary(locSelectedLibrary)
            let locAllCategoriesArr = libraryCategoryDictionary[0]?.categories || []
            setCategoriesArr(locAllCategoriesArr)
        }
    }, [libraryCategoryDictionary])
    //#endregion

    //#region To handle select library
    const onLibrarySelect = (e, value) => {
        console.log(value)
        setSelectedLibrary(value)
        let locAllCategoriesArr = value?.categories || []
        if (locAllCategoriesArr && locAllCategoriesArr.length > 0 && locAllCategoriesArr[0]?.category) {
            setCategoriesArr(locAllCategoriesArr)
            setSelectedCategory(locAllCategoriesArr[0])
            let locAllSubcategoriesArr = locAllCategoriesArr[0]?.subCategories || []
            if (locAllSubcategoriesArr && locAllSubcategoriesArr.length > 0) {
                setSelectedSubcategory(locAllSubcategoriesArr[0])
                setSubategoriesArr(locAllSubcategoriesArr)
            } else {
                setSubategoriesArr([])
                setSelectedSubcategory(null)
            }
        } else {
            setCategoriesArr([])
            setSelectedCategory(null)
            setSubategoriesArr([])
            setSelectedSubcategory(null)
        }
    }
    //#endregion

    //#region To handle select category
    const onCategorySelect = (e, value) => {
        if (value) {
            setSelectedCategory(value)
            let locAllSubcategoriesArr = value?.subCategories || []
            if (locAllSubcategoriesArr && locAllSubcategoriesArr.length > 0) {
                setSubategoriesArr(locAllSubcategoriesArr)
                setSelectedSubcategory(locAllSubcategoriesArr[0])
            } else {
                setSubategoriesArr([])
                setSelectedSubcategory(null)
            }
        } else {
            setSelectedCategory(null)
            setSubategoriesArr([])
            setSelectedSubcategory(null)
        }
    }
    //#endregion

    //#region To get suggection workitems
    useDebounce(() => {
        if (openItemAddDialog) {
            getItemsByFilter({
                name: searchText,
                category: selectedCategory?.category?._id,
                subCategory: selectedSubcategory?._id,
                libraryLable: selectedLibrary?._id || "",
                projectProfileId: projectProfileId
            })
                .then((data) => {
                    console.log(data)
                    setResultItemsArr(data)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setResultItemsArrLoader(false)
                })
        }
    }, 2000, [getFilteredWorkitemChagne]);

    useEffect(() => {
        setResultItemsArrLoader(true)
        setGetFilteredWorkitemChagne(!getFilteredWorkitemChagne)
    }, [searchText, selectedLibrary?._id, selectedCategory?.category?._id, selectedSubcategory?._id, openItemAddDialog])
    //#endregion

    //#region To handle category suggestion options filtering
    useDebounce(() => {
        let filteredCategories = []
        let exactMatch = null;
        categoriesArr.map((categoryData) => {
            const patt = new RegExp(category, "i");
            const res = patt.test(categoryData?.category?.name);
            if (res) {
                filteredCategories.push(categoryData);
            }
            if (category === categoryData?.category?.name) {
                exactMatch = categoryData;
            }
        });
        setCategoryObj(exactMatch)
        setSuggestedCategories(filteredCategories)
    }, 500, [category, categoriesArr])
    //#endregion

    //#region To handle subcategory suggestion options filtering
    useDebounce(() => {
        if (categoryObj?.category?._id) {
            let exactMatch = null;
            let filteredSubcategories = []
            categoryObj?.subCategories.map((subcategoryData) => {
                const patt = new RegExp(subcategory, "i");
                const res = patt.test(subcategoryData?.name);
                if (res) {
                    filteredSubcategories.push(subcategoryData);
                }
                if (subcategory === subcategoryData?.name) {
                    exactMatch = subcategoryData;
                }
            });
            setSubcategoryObj(exactMatch)
            setSuggestedSubcategories(filteredSubcategories)
        }
    }, 500, [subcategory])
    //#endregion

    //#region To reset add workitem whole form
    const resetAllData = () => {
        setOpenCategorySuggestion(false)
        setOpenSubcategorySuggestion(false)
        setSuggestedCategories([])
        setSuggestedSubcategories([])
        setCategory("")
        setSubcategory("")
        setItemName("")
        setRateTypeValue("Unit Rate Analysis")
        setItemTypeValue("Material + Labour")
        setForQty(1)
        setUnitValue("No.")
        setMaterialArrObj([])
        setMaterialAndLabourArrObj([])
        setLabourArrObj([])
        setMachineryAndEquipmentsArrObj([])
        setContractorAddOnsArr([])
        setAllItemsDetailsObjArr([])
        setTotalOfAllItems(0);
        setTotalAmountWithoutAdOn(0);
        setTotalTaxAmount(0);
        setNote("");
        setSelectedSuggestedWorkItem(null)
        setTotalOfAllItemsAlphabet([]);
        setTotalAmountWithoutAdOnAlphabet([]);
        setShowErrors(false)
    }
    //#endregion

    //#region To handle add new row in table form
    const addResource = (type) => {
        const otherObj = {
            name: "",
            qty: 0,
            qtyWastage: 0,
            totalQty: 0,
            unit: "No.",
            price: 0,
            tax: 0,
            rateIncludingTax: 0,
            totalCostIncludingTax: 0,
        }
        const addOnObj = {
            name: "",
            adOnType: "Other",
            rateType: "Rate in %",
            value: 0,
            total: 0
        }

        switch (type) {
            case "material":
                setMaterialArrObj([...materialArrObj, otherObj]);
                break;

            case "materialAndLabour":
                setMaterialAndLabourArrObj([...materialAndLabourArrObj, otherObj]);
                break;

            case "labour":
                setLabourArrObj([...labourArrObj, otherObj]);
                break;

            case "machineryAndEquipments":
                setMachineryAndEquipmentsArrObj([...machineryAndEquipmentsArrObj, otherObj]);
                break;

            case "contractorAddOns":
                setContractorAddOnsArr([...contractorAddOnsArr, addOnObj]);
                break;
        }
    }
    //#endregion

    //#region To handle onchange events of table form fields
    const changeResourceName = (type, index, value) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locMaterialArrObj[index].name = value;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locMaterialAndLabourArrObj[index].name = value;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locLabourArrObj[index].name = value;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locMachineryAndEquipmentsArrObj[index].name = value;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceQty = (type, index, value = 0) => {
        let locQty = 0;
        let locQtyWastage = 0;
        let locQtyTotal = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMaterialArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialArrObj[index].qty = locQty;
                locMaterialArrObj[index].qtyWastage = locQtyWastage;
                locMaterialArrObj[index].totalQty = locQtyTotal;
                locMaterialArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMaterialAndLabourArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialAndLabourArrObj[index].qty = locQty;
                locMaterialAndLabourArrObj[index].qtyWastage = locQtyWastage;
                locMaterialAndLabourArrObj[index].totalQty = locQtyTotal;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialAndLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locLabourArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locLabourArrObj[index].qty = locQty;
                locLabourArrObj[index].qtyWastage = locQtyWastage;
                locLabourArrObj[index].totalQty = locQtyTotal;
                locLabourArrObj[index].totalCostIncludingTax = (parseFloat(locLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMachineryAndEquipmentsArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMachineryAndEquipmentsArrObj[index].qty = locQty;
                locMachineryAndEquipmentsArrObj[index].qtyWastage = locQtyWastage;
                locMachineryAndEquipmentsArrObj[index].totalQty = locQtyTotal;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = (parseFloat(locMachineryAndEquipmentsArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceQtyWastage = (type, index, value = 0) => {
        let locQty = 0;
        let locQtyWastage = 0;
        let locQtyTotal = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locMaterialArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialArrObj[index].qtyWastage = locQtyWastage;
                locMaterialArrObj[index].totalQty = locQtyTotal;
                locMaterialArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locMaterialAndLabourArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialAndLabourArrObj[index].qtyWastage = locQtyWastage;
                locMaterialAndLabourArrObj[index].totalQty = locQtyTotal;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialAndLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locLabourArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locLabourArrObj[index].qtyWastage = locQtyWastage;
                locLabourArrObj[index].totalQty = locQtyTotal;
                locLabourArrObj[index].totalCostIncludingTax = (parseFloat(locLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locQty = parseFloat(locMachineryAndEquipmentsArrObj[index]?.qty || 0);
                locQtyWastage = parseFloat(value || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMachineryAndEquipmentsArrObj[index].qtyWastage = locQtyWastage;
                locMachineryAndEquipmentsArrObj[index].totalQty = locQtyTotal;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = (parseFloat(locMachineryAndEquipmentsArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceUnit = (type, index, value) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locMaterialArrObj[index].unit = value;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locMaterialAndLabourArrObj[index].unit = value;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locLabourArrObj[index].unit = value;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locMachineryAndEquipmentsArrObj[index].unit = value;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceRate = (type, index, value) => {
        let tax = 0;
        let amount = 0;
        let rateWithTax = 0;
        let qty = 0;
        let totalAmount = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                tax = parseFloat(locMaterialArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialArrObj[index].price = value;
                locMaterialArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                tax = parseFloat(locMaterialAndLabourArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialAndLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialAndLabourArrObj[index].price = value;
                locMaterialAndLabourArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                tax = parseFloat(locLabourArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locLabourArrObj[index].price = value;
                locLabourArrObj[index].rateIncludingTax = rateWithTax;
                locLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                tax = parseFloat(locMachineryAndEquipmentsArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMachineryAndEquipmentsArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMachineryAndEquipmentsArrObj[index].price = value;
                locMachineryAndEquipmentsArrObj[index].rateIncludingTax = rateWithTax;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = totalAmount;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceTax = (type, index, value) => {
        let tax = 0;
        let amount = 0;
        let rateWithTax = 0;
        let qty = 0;
        let totalAmount = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMaterialArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialArrObj[index].tax = value;
                locMaterialArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMaterialAndLabourArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialAndLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialAndLabourArrObj[index].tax = value;
                locMaterialAndLabourArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locLabourArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locLabourArrObj[index].tax = value;
                locLabourArrObj[index].rateIncludingTax = rateWithTax;
                locLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMachineryAndEquipmentsArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMachineryAndEquipmentsArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMachineryAndEquipmentsArrObj[index].tax = value;
                locMachineryAndEquipmentsArrObj[index].rateIncludingTax = rateWithTax;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = totalAmount;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }
    //#endregion

    //#region To handle select offering from suggestion offering list for table form
    const onSelectOffering = (type, index, offering) => {
        let tax = 0;
        let amount = 0;
        let rateWithTax = 0;
        let qty = 0;
        let totalAmount = 0;

        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                tax = parseFloat(offering?.tax || 0);
                amount = parseFloat(offering?.purchasePrice || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialArrObj[index].name = offering?.name;
                locMaterialArrObj[index]._id = offering?._id;
                locMaterialArrObj[index].unit = offering?.purchaseUnit;
                locMaterialArrObj[index].tax = tax;
                locMaterialArrObj[index].price = amount;
                locMaterialArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                tax = parseFloat(offering?.tax || 0);
                amount = parseFloat(offering?.purchasePrice || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialAndLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialAndLabourArrObj[index].name = offering?.name;
                locMaterialAndLabourArrObj[index]._id = offering?._id;
                locMaterialAndLabourArrObj[index].unit = offering?.purchaseUnit;
                locMaterialAndLabourArrObj[index].tax = tax;
                locMaterialAndLabourArrObj[index].price = amount;
                locMaterialAndLabourArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                tax = parseFloat(offering?.tax || 0);
                amount = parseFloat(offering?.purchasePrice || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locLabourArrObj[index].name = offering?.name;
                locLabourArrObj[index]._id = offering?._id;
                locLabourArrObj[index].unit = offering?.purchaseUnit;
                locLabourArrObj[index].tax = tax;
                locLabourArrObj[index].price = amount;
                locLabourArrObj[index].rateIncludingTax = rateWithTax;
                locLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                tax = parseFloat(offering?.tax || 0);
                amount = parseFloat(offering?.purchasePrice || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMachineryAndEquipmentsArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMachineryAndEquipmentsArrObj[index].name = offering?.name;
                locMachineryAndEquipmentsArrObj[index]._id = offering?._id;
                locMachineryAndEquipmentsArrObj[index].unit = offering?.purchaseUnit;
                locMachineryAndEquipmentsArrObj[index].tax = tax;
                locMachineryAndEquipmentsArrObj[index].price = amount;
                locMachineryAndEquipmentsArrObj[index].rateIncludingTax = rateWithTax;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = totalAmount;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }
    //#endregion

    //#region To set data value from clicked left side workitem to whole right side form 
    const setAllValues = (itemData) => {
        setSelectedSuggestedWorkItem(itemData)
        let locMaterialArrObj = []
        let locMaterialAndLabourArrObj = []
        let locLabourArrObj = []
        let locMachineryAndEquipmentsArrObj = []
        let locContractorAddOnsArr = []
        let locResources = itemData?.resources || []
        let adOns = itemData?.adOns || []

        let locTotalAmountForAdOn = 0;

        locResources.map((resourceData) => {
            let recQty = resourceData?.quantity || 0
            let recQtyWastage = 0;
            let recTotalQty = resourceData?.quantityWithWastage || 0
            let locRateIncludingTax = 0;
            let locTotalCostIncludingTax = 0;
            if (recTotalQty === 0) {
                recQtyWastage = 0;
                recTotalQty = recQty;
            } else if (recTotalQty === recQty) {
                recQtyWastage = 0;
            } else {
                recQtyWastage = parseFloat((parseFloat(recTotalQty - recQty) / recQty) * 100);
            }
            locRateIncludingTax = parseFloat(resourceData?.resource?.purchasePrice || 0) + parseFloat((parseFloat(resourceData?.resource?.purchasePrice || 0) * parseFloat(resourceData?.resource?.tax || 0)) / 100)
            locTotalCostIncludingTax = locRateIncludingTax * recTotalQty
            locTotalAmountForAdOn = locTotalAmountForAdOn + locTotalCostIncludingTax;

            const newObj = {
                _id: resourceData?.resource?._id,
                name: resourceData?.resource?.name || "",
                qty: parseFloat(recQty || 0).toFixed(2),
                qtyWastage: parseFloat(recQtyWastage || 0).toFixed(2),
                totalQty: parseFloat(recTotalQty || 0).toFixed(2),
                unit: resourceData?.resource?.purchaseUnit || "",
                price: parseFloat(resourceData?.resource?.purchasePrice || 0).toFixed(2),
                tax: parseFloat(resourceData?.resource?.tax || 0).toFixed(2),
                rateIncludingTax: parseFloat(locRateIncludingTax || 0).toFixed(2),
                totalCostIncludingTax: parseFloat(locTotalCostIncludingTax).toFixed(2),
            }

            if (resourceData?.resource?.model === "Material") {
                locMaterialArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Material + Labour") {
                locMaterialAndLabourArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Labour") {
                locLabourArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Machinery & Equipments") {
                locMachineryAndEquipmentsArrObj.push(newObj)
            }
        })

        adOns.map((adOn) => {
            let adOnTotalAmount = 0;
            if (adOn?.rateType == "Rate in %") {
                adOnTotalAmount = parseFloat((parseFloat(locTotalAmountForAdOn || 0) * parseFloat(adOn?.value || 0)) / 100).toFixed(2);
            } else {
                adOnTotalAmount = parseFloat(adOn?.value || 0).toFixed(2);
            }

            locContractorAddOnsArr.push({
                name: adOn?.name || "",
                adOnType: adOn?.type || "Other",
                rateType: adOn?.rateType || "Rate in %",
                value: parseFloat(adOn?.value || 0).toFixed(2),
                total: adOnTotalAmount
            })
        })

        setMaterialArrObj(locMaterialArrObj)
        setMaterialAndLabourArrObj(locMaterialAndLabourArrObj)
        setLabourArrObj(locLabourArrObj)
        setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj)
        setContractorAddOnsArr(locContractorAddOnsArr)
        setCategory(itemData?.category || "")
        setSubcategory(itemData?.subCategory || "")
        setItemName(itemData?.name || "")
        setRateTypeValue(itemData?.rateType || "")
        setItemTypeValue(itemData?.resourceType || "")
        setForQty(itemData?.forQty || 1)
        setUnitValue(itemData?.unit || "No.")
    }
    //#endregion

    //#region To calculate total amount, tax, and alphabetical order
    useEffect(() => {
        let locAllArr = [];
        let serialNo = 0;
        let locOtherItemsTotalAmount = 0;
        let locAllItemTotalAmount = 0;
        let locTotalTaxAmount = 0;
        let locTotalOfAllItemsAlphabets = [];
        let locTotalAmountWithoutAdOnAlphabets = [];

        if (materialArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            materialArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "material",
                arr: materialArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (materialAndLabourArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            materialAndLabourArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0);
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "materialAndLabour",
                arr: materialAndLabourArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (labourArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            labourArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "labour",
                arr: labourArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (machineryAndEquipmentsArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            machineryAndEquipmentsArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "machineryAndEquipments",
                arr: machineryAndEquipmentsArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        locTotalOfAllItemsAlphabets = [...locTotalAmountWithoutAdOnAlphabets]
        locAllItemTotalAmount = locOtherItemsTotalAmount;
        if (contractorAddOnsArr.length > 0) {
            let totalAmount = 0;
            contractorAddOnsArr.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.total || 0)
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "contractorAddOns",
                arr: contractorAddOnsArr,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locAllItemTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalOfAllItemsAlphabets.push(alp[serialNo]);
        }

        setTotalOfAllItemsAlphabet(locTotalOfAllItemsAlphabets)
        setTotalAmountWithoutAdOnAlphabet(locTotalAmountWithoutAdOnAlphabets)
        setTotalTaxAmount(locTotalTaxAmount.toFixed(2))
        setTotalOfAllItems(locAllItemTotalAmount.toFixed(2))
        setTotalAmountWithoutAdOn(locOtherItemsTotalAmount.toFixed(2))
        setAllItemsDetailsObjArr(locAllArr)
    }, [materialArrObj, materialAndLabourArrObj, labourArrObj, machineryAndEquipmentsArrObj, contractorAddOnsArr])

    useDebounce(() => {
        let locContractorAddOnsArr = [];
        contractorAddOnsArr.map((contractorAddOnData) => {
            locContractorAddOnsArr.push({
                name: contractorAddOnData?.name || "",
                adOnType: contractorAddOnData?.adOnType || "",
                rateType: contractorAddOnData?.rateType || "",
                value: contractorAddOnData?.value || 0,
                total: ((parseFloat(totalAmountWithoutAdOn || 0) * parseFloat(contractorAddOnData?.value || 0)) / 100).toFixed(2)
            })
        })
        setContractorAddOnsArr(locContractorAddOnsArr)
    }, 700, [totalAmountWithoutAdOn])
    //#endregion

    //#region To hide error messages
    useDebounce(() => {
        if (showErrors) {
            setShowErrors(false)
        }
    }, 500, [materialArrObj, materialAndLabourArrObj, labourArrObj,
        allItemsDetailsObjArr, machineryAndEquipmentsArrObj,
        contractorAddOnsArr, category, subcategory, itemName,
        rateTypeValue, itemTypeValue])
    //#endregion

    //#region To remove resources from table form
    const deleteResource = (type, index) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj]
                locMaterialArrObj.splice(index, 1)
                setMaterialArrObj([...locMaterialArrObj]);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj]
                locMaterialAndLabourArrObj.splice(index, 1)
                setMaterialAndLabourArrObj([...locMaterialAndLabourArrObj]);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj]
                locLabourArrObj.splice(index, 1)
                setLabourArrObj([...locLabourArrObj]);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj]
                locMachineryAndEquipmentsArrObj.splice(index, 1)
                setMachineryAndEquipmentsArrObj([...locMachineryAndEquipmentsArrObj]);
                break;

            case "contractorAddOns":
                let locContractorAddOnsArr = [...contractorAddOnsArr]
                locContractorAddOnsArr.splice(index, 1)
                setContractorAddOnsArr([...locContractorAddOnsArr]);
                break;
        }
    }
    //#endregion

    const onAddSubmit = async () => {
        setAddLoader(true)
        let isNotValid = false;
        if (!(category && category.length > 0)) {
            isNotValid = true;
        }
        if (!(subcategory && subcategory.length > 0)) {
            isNotValid = true;
        }
        if (!(itemName && itemName.length > 0)) {
            isNotValid = true;
        }
        if (!(rateTypeValue && rateTypeValue.length > 0)) {
            isNotValid = true;
        }
        if (!(itemTypeValue && itemTypeValue.length > 0)) {
            isNotValid = true;
        }

        let totalAmountWithoutTax = 0;
        if (rateTypeValue !== "Direct Rate") {
            allItemsDetailsObjArr && allItemsDetailsObjArr.map((itemsDetailsObj) => {
                let arr = itemsDetailsObj?.arr || []
                let innerTotalAmountWithoutTax = 0;
                if (itemsDetailsObj?.name === "contractorAddOns") {
                    arr.map((singleData) => {
                        if (!(singleData?.name && singleData.name.length > 0)) {
                            isNotValid = true;
                        }
                        innerTotalAmountWithoutTax = parseFloat(innerTotalAmountWithoutTax) + parseFloat(singleData?.total || 0);
                    })
                } else {
                    arr.map((singleData) => {
                        if (!(singleData?.name && singleData.name.length > 0)) {
                            isNotValid = true;
                        }
                        let locTotal = (parseFloat(singleData?.totalQty) * parseFloat(singleData?.price)).toFixed(2);
                        innerTotalAmountWithoutTax = parseFloat(innerTotalAmountWithoutTax) + parseFloat(locTotal);
                    })
                }
                totalAmountWithoutTax = parseFloat(totalAmountWithoutTax) + parseFloat(innerTotalAmountWithoutTax);
            })
        }

        if (isNotValid) {
            setShowErrors(true)
        } else {
            if (rateTypeValue === "Direct Rate") {
                let locTotalTaxDR = parseFloat((parseFloat(totalOfAllItems) * parseFloat(allOverTaxInParcentage)) / 100)
                const locParentData = {
                    name: itemName,
                    category: category,
                    categoryObj: categoryObj?.category,
                    subCategory: subcategory,
                    subCategoryId: subcategoryObj?._id,
                    resourceType: itemTypeValue,
                    rate: totalOfAllItems,
                    rateType: rateTypeValue,
                    unit: unitValue,
                    forQty: forQty,
                    ratePerUnit: (parseFloat(totalOfAllItems) / parseFloat(forQty)).toFixed(2),
                    ratePerUnitWithTax: (parseFloat(allOverRateIncludingTax) / parseFloat(forQty)).toFixed(2),
                    taxPerUnit: (parseFloat(locTotalTaxDR) / parseFloat(forQty)).toFixed(2),
                    parentId: curWorkItem?._id || null,
                    numberOfChildren: curWorkItem?.numberOfChildren || 0,
                    ancestors: curWorkItem?.ancestors || []
                }
                await createWorkItem(false, { isRateEditable: true, itemName: itemName }, false, locParentData, selectedLibrary)
            } else if (selectedSuggestedWorkItem && selectedSuggestedWorkItem?._id) {
                //create workitem from suggested library workItem
                const dataObj = {
                    allItemsDetailsObjArr: allItemsDetailsObjArr,
                    workItemTitle: itemName,
                    workItemCategory: category,
                    workItemSubcategory: subcategory,
                    workItemQty: forQty,
                    workItemResourceType: itemTypeValue,
                    workItemRate: totalOfAllItems,
                    workItemRateType: rateTypeValue,
                    workItemUnit: unitValue,
                    categoryObj: categoryObj?.category,
                    subCategoryId: subcategoryObj?._id,
                    libraryId: selectedSuggestedWorkItem?.codeLibrary,
                    libraryName: selectedSuggestedWorkItem?.libraryLable,
                    itemPackageId: selectedSuggestedWorkItem?._id,
                    parentId: curWorkItem?._id || null,
                    numberOfChildren: curWorkItem?.numberOfChildren || 0,
                    ratePerUnit: (parseFloat(totalOfAllItems) / parseFloat(forQty)).toFixed(2),
                    ratePerUnitWithoutTax: (parseFloat(totalAmountWithoutTax) / parseFloat(forQty)).toFixed(2),
                    totalTax: (parseFloat(totalTaxAmount) / parseFloat(forQty)).toFixed(2)
                }
                await createWorkItem(false, dataObj, curWorkItem?.isWorkGroup, curWorkItem, selectedLibrary)
            } else {
                const dataObj = {
                    allItemsDetailsObjArr: allItemsDetailsObjArr,
                    workItemTitle: itemName,
                    workItemCategory: category,
                    workItemSubcategory: subcategory,
                    workItemQty: forQty,
                    workItemResourceType: itemTypeValue,
                    workItemRate: totalOfAllItems,
                    workItemRateType: rateTypeValue,
                    workItemUnit: unitValue,
                    parentId: curWorkItem?._id || null,
                    numberOfChildren: curWorkItem?.numberOfChildren || 0,
                    categoryObj: categoryObj?.category,
                    subCategoryId: subcategoryObj?._id,
                    projectName: "project_1",
                    ratePerUnit: (parseFloat(totalOfAllItems) / parseFloat(forQty)).toFixed(2),
                    ratePerUnitWithoutTax: (parseFloat(totalAmountWithoutTax) / parseFloat(forQty)).toFixed(2),
                    totalTax: (parseFloat(totalTaxAmount) / parseFloat(forQty)).toFixed(2),
                }
                await createWorkItem(true, dataObj, curWorkItem?.isWorkGroup, curWorkItem, selectedLibrary)
            }
            setOpenItemAddDialog(false)
            resetAllData()
        }
        setAddLoader(false)
    }

    return (
        <NormalDrawer
            openDrawer={openItemAddDialog}
            setOpenDrawer={setOpenItemAddDialog}
            anchor={"right"}
            title={"Add Work Item"}
            width={"100vw"}
            content={<div className={classes.mainCont} >
                {openItemAddDialog && (<>
                    <div style={{ width: "100%", height: "4px" }} >
                        {addLoader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bodyCont} >
                        <div className={classes.sideBar} >
                            <h3 className={classes.titleSty} >Browse from library</h3>
                            <TextField
                                variant="outlined"
                                value={searchText}
                                onChange={(e) => { setSearchText(e.target.value); }}
                                fullWidth
                                placeholder={"Search"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ color: "#7C7C8D" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                            />
                            <p className={classes.textLable} >Cost Libraries </p>
                            <Autocomplete
                                id="grouped-demo"
                                freeSolo
                                value={selectedLibrary}
                                disableClearable
                                options={libraryCategoryDictionary}
                                getOptionLabel={(option) => { return option?.name || " "; }}
                                groupBy={(option) => option.parentTitle}
                                getOptionSelected={(option) => {
                                    return option?._id === selectedLibrary?._id
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="select library"
                                    />
                                )}
                                onChange={onLibrarySelect}
                                fullWidth
                                className={classes.chartAccCont}
                                size="small"
                            />
                            <p className={classes.textLable} >Category</p>
                            <Autocomplete
                                value={selectedCategory}
                                options={categoriesArr}
                                getOptionLabel={(option) => { return option?.category?.name || "" }}
                                getOptionSelected={(option) => {
                                    return option?.category?._id === selectedCategory?.category?._id
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Select category"
                                    />
                                )}
                                onChange={onCategorySelect}
                                fullWidth
                                size="small"
                            />
                            <p className={classes.textLable} >Subcategory</p>
                            <Autocomplete
                                value={selectedSubcategory}
                                options={subcategoriesArr}
                                getOptionLabel={(option) => { return option?.name || "" }}
                                getOptionSelected={(option) => {
                                    return option?._id === selectedSubcategory?._id
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{ margin: "0px" }}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Select subcategory"
                                    />
                                )}
                                onChange={(e, value) => {
                                    setSelectedSubcategory(value)
                                }}
                                fullWidth
                                size="small"
                            />
                            <div className={classes.progressCont} >
                                {resultItemsArrLoader && (
                                    <LinearProgress />
                                )}
                            </div>
                            <div className={classes.itemsCont} >
                                {(resultItemsArr && resultItemsArr.length > 0) ? (<>
                                    {resultItemsArr && resultItemsArr.map((ItemData, i) => (
                                        <div
                                            key={i}
                                            className={classes.singleItemCont}
                                            onClick={() => { setAllValues(ItemData) }}
                                        >
                                            {ItemData?.name}
                                        </div>
                                    ))}
                                </>) : (<div className={classes.emptySty} >
                                    <VscInbox style={{ fontSize: "50px" }} />
                                    <p>No Workitem Available!</p>
                                </div>)}
                            </div>
                        </div>

                        <div className={classes.itemDetailsAndBtnCont} >
                            <div className={classes.titleAndBtnCont} >
                                <div className={classes.subheaderCont} >
                                    <h3 className={classes.workItemTitle} >Work item</h3>
                                    {selectedSuggestedWorkItem ? (
                                        <IconButton onClick={resetAllData} >
                                            <GrPowerReset style={{ color: "black", fontSize: "20px" }} />
                                        </IconButton>
                                    ) : null}
                                </div>
                                <Button
                                    color='primary'
                                    variant="contained"
                                    onClick={onAddSubmit}
                                    disabled={addLoader}
                                >
                                    Add
                                </Button>
                            </div>
                            <div className={classes.itemDetailsCont} >
                                <div className={classes.categorySubcategoryCont} >
                                    <div className={classes.categoryCont} >
                                        <ClickAwayListener onClickAway={() => { setOpenCategorySuggestion(false) }}>
                                            <TextField
                                                variant="outlined"
                                                value={category}
                                                onChange={(e) => {
                                                    setCategory(e.target.value);
                                                    setCategoryObj(null)
                                                }}
                                                fullWidth
                                                placeholder={"Enter category"}
                                                label={"Category"}
                                                size="small"
                                                onClick={() => { setOpenCategorySuggestion(true) }}
                                            />
                                        </ClickAwayListener>
                                        {openCategorySuggestion && (
                                            <div className={classes.suggestionCont} >
                                                {suggestedCategories && suggestedCategories.map((suggestedcategoryData, i) => (
                                                    <div
                                                        key={i}
                                                        className={classes.singleSuggetion}
                                                        onClick={() => {
                                                            setCategory(suggestedcategoryData?.category?.name)
                                                            setCategoryObj(suggestedcategoryData)
                                                            setSuggestedSubcategories(suggestedcategoryData?.subCategories)
                                                        }}
                                                    >
                                                        {suggestedcategoryData?.category?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={classes.errorMsg} >
                                            {category && category.length > 1 ? null : showErrors ? "Category is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.subcategoryCont} >
                                        <ClickAwayListener onClickAway={() => { setOpenSubcategorySuggestion(false) }}>
                                            <TextField
                                                variant="outlined"
                                                value={subcategory}
                                                onChange={(e) => { setSubcategory(e.target.value); }}
                                                fullWidth
                                                placeholder={"Enter subcategory"}
                                                label={"Subcategory"}
                                                size="small"
                                                onClick={() => { setOpenSubcategorySuggestion(true) }}
                                            />
                                        </ClickAwayListener>
                                        {openSubcategorySuggestion && (
                                            <div className={classes.suggestionCont} >
                                                {suggestedSubcategories && suggestedSubcategories.map((suggestedSubcategoryData, i) => (
                                                    <div
                                                        key={i}
                                                        className={classes.singleSuggetion}
                                                        onClick={() => {
                                                            setSubcategory(suggestedSubcategoryData?.name)
                                                            setSubcategoryObj(suggestedSubcategoryData)
                                                        }}
                                                    >
                                                        {suggestedSubcategoryData?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={classes.errorMsg} >
                                            {subcategory && subcategory.length > 1 ? null : showErrors ? "Subcategory is required" : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.nameRateTypeItemTypeCont} >
                                    <div className={classes.itemNameCont} >
                                        <TextField
                                            variant="outlined"
                                            value={itemName}
                                            fullWidth
                                            onChange={(e) => { setItemName(e.target.value); }}
                                            placeholder={"Enter item name"}
                                            label={"Item Name"}
                                            size="small"
                                        />
                                        <div className={classes.errorMsg} >
                                            {itemName && itemName.length > 1 ? null : showErrors ? "Item name is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.rateTypeCont} >
                                        <Autocomplete
                                            value={rateTypeValue}
                                            options={rateType}
                                            getOptionLabel={(option) => { return option || "" }}
                                            getOptionSelected={(option) => {
                                                return option === rateTypeValue
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Select rate type"
                                                    label={"Rate Type"}
                                                />
                                            )}
                                            onChange={(e, value) => { setRateTypeValue(value) }}
                                            size="small"
                                            fullWidth
                                        />
                                        <div className={classes.errorMsg} >
                                            {rateTypeValue && rateTypeValue.length > 1 ? null : showErrors ? "Rate type is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.itemTypeCont} >
                                        <Autocomplete
                                            value={itemTypeValue}
                                            options={itemTypesArr}
                                            getOptionLabel={(option) => { return option || "" }}
                                            getOptionSelected={(option) => {
                                                return option === itemTypeValue
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Select item type"
                                                    label={"Item Type"}
                                                />
                                            )}
                                            onChange={(e, value) => { setItemTypeValue(value) }}
                                            size="small"
                                            fullWidth
                                        />
                                        <div className={classes.errorMsg} >
                                            {itemTypeValue && itemTypeValue.length > 1 ? null : showErrors ? "Item type is required" : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.qtyUnitRateCont} >
                                    <TextField
                                        variant="outlined"
                                        value={forQty}
                                        onChange={(e) => { setForQty(e.target.value); }}
                                        placeholder={"Quantity"}
                                        label={"For Quantity"}
                                        size="small"
                                        type="number"
                                        className={classes.qtyCont}
                                    />
                                    <UnitAutocomplete
                                        value={unitValue}
                                        onChange={(e, value) => { setUnitValue(value) }}
                                        className={classes.unitCont}
                                    />
                                    <TextField
                                        variant="outlined"
                                        value={totalOfAllItems}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            setTotalOfAllItems(val);
                                            let locRateIncludingTax = parseFloat(val) + parseFloat((val * allOverTaxInParcentage) / 100)
                                            setAllOverRateIncludingTax(locRateIncludingTax.toFixed(2))
                                        }}
                                        placeholder={"Enter rate"}
                                        label={"Rate"}
                                        size="small"
                                        type="number"
                                        className={classes.rateCont}
                                        disabled={rateTypeValue === "Direct Rate" ? false : true}
                                    />
                                    {rateTypeValue === "Direct Rate" ? (<>
                                        <TextField
                                            variant="outlined"
                                            value={allOverTaxInParcentage}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                setAllOverTaxInParcentage(val);
                                                let locRateIncludingTax = parseFloat(totalOfAllItems) + parseFloat((val * totalOfAllItems) / 100)
                                                setAllOverRateIncludingTax(locRateIncludingTax.toFixed(2))
                                            }}
                                            placeholder={"Tax parcentage"}
                                            label={"Tax in %"}
                                            size="small"
                                            type="number"
                                            className={classes.rateCont}
                                        />
                                        <TextField
                                            variant="outlined"
                                            value={allOverRateIncludingTax}
                                            placeholder={"Rate including tax"}
                                            label={"Rate including tax"}
                                            size="small"
                                            type="number"
                                            className={classes.rateCont}
                                            disabled
                                        />
                                    </>) : null}
                                </div>
                                {rateTypeValue === "Direct Rate" ? null : (
                                    <table className={classes.tableSty} >
                                        <thead>
                                            <tr>
                                                <th className={classes.resourcesTableSty} >
                                                    <div className={classes.lableBtnCont} >
                                                        <p>Resources</p>
                                                        <MyPopOver
                                                            closeOnClick={true}
                                                            appearContent={
                                                                <Button className={classes.btnCont} >
                                                                    <AddIcon style={{ marginRight: "5px", fontSize: "15px", color: "white" }} />
                                                                    <ExpandMoreIcon style={{ fontSize: "15px", color: "white" }} />
                                                                </Button>
                                                            }
                                                            showContent={<div className={classes.optionsCont} >
                                                                {itemTypesForResourcesArr && itemTypesForResourcesArr.map((itemType, i) => (
                                                                    <div
                                                                        key={i}
                                                                        className={classes.typeSingleOptionSty}
                                                                        onClick={() => { addResource(itemTypesForResourcesValArr[i]) }}
                                                                    >
                                                                        {itemType}
                                                                    </div>
                                                                ))}
                                                            </div>}
                                                        />
                                                    </div>
                                                </th>
                                                <th className={classes.qtyTableSty} >Qty</th>
                                                <th className={classes.qtyWastageTableSty} >Qty <span style={{ fontSize: "9px", fontWeight: "400" }} >wastage</span> (%)</th>
                                                <th className={classes.qtyTotalTableSty} >Total Qty</th>
                                                <th className={classes.unitTableSty} >Unit</th>
                                                <th className={classes.rateTableSty} >Rate</th>
                                                <th className={classes.taxTableSty} >Tax</th>
                                                <th className={classes.rateTaxTableSty} >Rate <span style={{ fontSize: "9px", fontWeight: "400" }} >Including Tax</span></th>
                                                <th className={classes.totalTableSty} >Total Cost <span style={{ fontSize: "9px", fontWeight: "400" }} >Including Tax</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allItemsDetailsObjArr && allItemsDetailsObjArr.map((ItemDetailsObj, i) => (<>
                                                {ItemDetailsObj?.name === "contractorAddOns" && ItemDetailsObj?.arr && ItemDetailsObj.arr.length > 0 ? (
                                                    <tr key={i} >
                                                        <td colspan="9" style={{ fontSize: "15px", fontWeight: "510", textAlign: "right", paddingRight: "10px" }}  >
                                                            Total ({totalAmountWithoutAdOnAlphabet.join(" + ")}) : {parseFloat(totalAmountWithoutAdOn || 0).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <th
                                                        colspan="8"
                                                        style={{
                                                            textAlign: "left",
                                                            fontSize: "15px",
                                                            fontWeight: "510",
                                                            paddingLeft: "5px"
                                                        }}
                                                    >{`${ItemDetailsObj?.alphabet}.${keyValuePair[ItemDetailsObj?.name]}`}</th>
                                                    <th style={{ paddingRight: "10px", textAlign: "right" }} >{ItemDetailsObj?.totalAmount}</th>
                                                </tr>
                                                {ItemDetailsObj && ItemDetailsObj.arr.map((resourceData, j) => {
                                                    if (ItemDetailsObj?.name === "contractorAddOns") {
                                                        return (<>
                                                            <tr className="resourceLine" key={j} >
                                                                <td colspan="3"  >
                                                                    <div style={{ width: "100%", paddingTop: "12px" }} >
                                                                        <input
                                                                            value={resourceData?.name}
                                                                            onChange={(e) => {
                                                                                let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                                locContractorAddOnsArr[j].name = e.target.value;
                                                                                setContractorAddOnsArr(locContractorAddOnsArr);
                                                                            }}
                                                                            placeholder="Enter item name"
                                                                            type="text"
                                                                            className={classes.textInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                        <div className={classes.tableErrMsg} >
                                                                            {resourceData?.name && resourceData.name.length > 1 ? null : showErrors ? "Name is required" : null}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td colspan="2" >
                                                                    <Autocomplete
                                                                        freeSolo
                                                                        value={resourceData?.adOnType}
                                                                        options={["Tax", "Other"]}
                                                                        getOptionLabel={(option) => { return option || " "; }}
                                                                        getOptionSelected={(option) => {
                                                                            return option === resourceData?.adOnType
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                placeholder="select add on type"
                                                                            />
                                                                        )}
                                                                        onChange={(e, value) => {
                                                                            let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                            locContractorAddOnsArr[j].adOnType = value;
                                                                            setContractorAddOnsArr(locContractorAddOnsArr);
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                    />
                                                                </td>
                                                                <td colspan="2"  >
                                                                    <Autocomplete
                                                                        freeSolo
                                                                        value={resourceData?.rateType}
                                                                        options={["Rate in Amount", "Rate in %"]}
                                                                        getOptionLabel={(option) => { return option || " "; }}
                                                                        getOptionSelected={(option) => {
                                                                            return option === resourceData?.rateType
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                placeholder="select type"
                                                                            />
                                                                        )}
                                                                        onChange={(e, value) => {
                                                                            let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                            locContractorAddOnsArr[j].rateType = value;
                                                                            if (value == "Rate in Amount") {
                                                                                locContractorAddOnsArr[j].total = locContractorAddOnsArr[j]?.value || 0;
                                                                            } else {
                                                                                let locTotal = parseFloat((parseFloat(totalAmountWithoutAdOn) * parseFloat(locContractorAddOnsArr[j]?.value || 0)) / 100);
                                                                                locContractorAddOnsArr[j].total = locTotal;
                                                                            }
                                                                            setContractorAddOnsArr(locContractorAddOnsArr);
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        value={resourceData?.value}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                            locContractorAddOnsArr[j].value = value;
                                                                            if (locContractorAddOnsArr[j].rateType === "Rate in Amount") {
                                                                                locContractorAddOnsArr[j].total = value;
                                                                            } else {
                                                                                let locTotal = parseFloat((parseFloat(totalAmountWithoutAdOn) * parseFloat(value)) / 100);
                                                                                locContractorAddOnsArr[j].total = locTotal;
                                                                            }
                                                                            setContractorAddOnsArr(locContractorAddOnsArr);
                                                                        }}
                                                                        placeholder="Enter value"
                                                                        type="number"
                                                                        className={classes.numberInputSty}
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </td>
                                                                <td style={{ paddingRight: "10px" }} >
                                                                    <div className={classes.totalDeleteCont} >
                                                                        <p>{parseFloat(resourceData?.total || 0).toFixed(2)}</p>
                                                                        <DeleteIcon onClick={() => { deleteResource(ItemDetailsObj?.name, j) }} className="resourceDeleteIcon" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                    } else {
                                                        return (
                                                            <WorkitemResourceInputTable
                                                                resourceData={resourceData}
                                                                changeResourceName={changeResourceName}
                                                                ItemDetailsObj={ItemDetailsObj}
                                                                j={j}
                                                                projectProfileId={projectProfileId}
                                                                showErrors={showErrors}
                                                                onSelectOffering={onSelectOffering}
                                                                changeResourceQty={changeResourceQty}
                                                                changeResourceQtyWastage={changeResourceQtyWastage}
                                                                changeResourceUnit={changeResourceUnit}
                                                                changeResourceRate={changeResourceRate}
                                                                changeResourceTax={changeResourceTax}
                                                                deleteResource={deleteResource}
                                                                selectedLibrary={selectedLibrary}
                                                            />
                                                        )
                                                    }
                                                })}
                                            </>))}
                                            {allItemsDetailsObjArr && allItemsDetailsObjArr.length > 0 ? (<>
                                                {contractorAddOnsArr && contractorAddOnsArr.length > 0 ? null : (
                                                    <tr>
                                                        <td colspan="9" style={{ fontSize: "15px", fontWeight: "500", textAlign: "right", paddingRight: "10px" }}  >
                                                            Total ({totalAmountWithoutAdOnAlphabet.join(" + ")}) : {parseFloat(totalAmountWithoutAdOn || 0).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td colspan="9" style={{ fontSize: "15px", fontWeight: "510", textAlign: "right", paddingRight: "10px" }}  >
                                                        Grand Total ({totalOfAllItemsAlphabet.join(" + ")}) : {parseFloat(totalOfAllItems || 0).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="9" style={{ padding: "0px 5px", fontSize: "15px", fontWeight: "500", textAlign: "left" }} >
                                                        <div style={{ textAlign: "left" }} >
                                                            Total Tax Component: {parseFloat(totalTaxAmount).toFixed(2)}
                                                        </div>
                                                        {forQty && (
                                                            <div style={{ textAlign: "right" }} >
                                                                Rates per No: {parseFloat((totalOfAllItems || 0) / (forQty || 1)).toFixed(2)}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>) : null}
                                        </tbody>
                                    </table>
                                )}
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Multiline"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={note}
                                    onChange={(e) => { setNote(e.target.value); }}
                                    defaultValue="Default Value"
                                    variant="outlined"
                                    size="small"
                                    style={{ marginTop: "20px" }}
                                />
                            </div>
                        </div>
                    </div>
                </>)}
            </div>}
        />
    );
};

export default BudgetCostAddWorkItem;