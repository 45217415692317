import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Checkbox, Button } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { updateWorkItem } from "./api.call";
import BudgetCostAddWorkItem from "./BudgetCostAddWorkItem";
import EditWorkItem from "./EditWorkItem";
import TextFieldNumberWithoutAnyStyle from "../styled/CommonComponents/TextFieldNumberWithoutAnyStyle";
import "./index.css"
import { GoTasklist } from "react-icons/go";
import Tooltip from '@material-ui/core/Tooltip';
import { RiBillLine } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
    itemTitleSty: {
        width: "500px"
    },
    singleItemTitleBtnContCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    optionsCont: {
        padding: "5px"
    },
    singleOption: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        "& img": {
            width: "20px",
            height: "auto",
            marginRight: "10px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "&:hover": {
            backgroundColor: "#e2e2e2"
        }
    },
    showChildIcon: {
        transform: "rotate(0deg)",
        transition: "all .3s ease-in-out",
    },
    hideChildIcon: {
        transform: "rotate(-90deg)",
        transition: "all .3s ease-in-out",
    },
    clickAble: {
        textDecoration: "underline",
        color: "blue",
        cursor: "pointer",
    },
    width200: {
        width: "200px"
    },
    width100: {
        width: "100px"
    },
    width140: {
        width: "140px"
    },
    width150: {
        width: "150px"
    },
    qtySty1: {
        "& input": {
            fontWeight: "600",
            width: "100%",
            textAlign: "right",
            fontSize: "12px"
        }
    },
    qtySty2: {
        "& input": {
            fontWeight: "400",
            width: "100%",
            textAlign: "right",
            fontSize: "12px"
        }
    },
    linkedItemsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        "& .MuiIconButton-root": {
            padding: "10px"
        }
    },
    iconSty: {
        fontSize: "25px",
        color: "white",
        borderRadius: "12px",
        padding: "3px"
    }
}));

const BudgetSingleWorkItem = ({
    workitemData, createGroupItem, createWorkItem, paddingLeft, parentChildrenMap,
    isCollapseChild, setCollapseChild, workitems, setWorkItems, idWorkitemMap,
    libraryCategoryDictionary, setLibraryCategoryDictionary, needToMakeDisable,
    subheadData, setOpenApprovedAmountDetails, setOpenGrossValueCertifiteDetails,
    setOpenNetCertifitePayableDetails, setOpenPaidDetails, handleCheck,
    setOpenSubmittedAmountDetails, setSelectedWorkitemData, allSelectedIds,
    setTaskOpenData
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isCollapseSubChild, setCollapseSubChild] = useState(true)
    const [title, setTitle] = useState("")
    const [openItemAddDialog, setOpenItemAddDialog] = useState(false)
    const [openItemEditDialog, setOpenItemEditDialog] = useState(false)
    const [initialStoper1, setInitialStoper1] = useState(false)
    const [initialStoper2, setInitialStoper2] = useState(false)
    const [initialStoper3, setInitialStoper3] = useState(false)
    const [curQty, setCurQty] = useState(0)

    useEffect(() => {
        setCollapseSubChild(isCollapseChild)
    }, [isCollapseChild])

    useEffect(() => {
        setTitle(workitemData?.name || "")
        setCurQty(workitemData?.qty || 0)
    }, [])

    useDebounce(() => {
        if (initialStoper1) {
            updateWorkItem({
                qty: curQty,
                _id: workitemData?._id
            })
                .then((data) => {
                    console.log("data")
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setInitialStoper1(true)
        }
    }, 3000, [curQty]);

    useDebounce(() => {
        let arr = [];
        workitems.map((workitem) => {
            if (workitemData?._id == workitem?._id) {
                arr.push({
                    ...workitem,
                    qty: curQty
                })
            } else {
                arr.push(workitem)
            }
        })
        setWorkItems(arr)
    }, 1000, [curQty])

    const onQtySubmit = async (e) => {
        e.preventDefault();
        await updateWorkItem({
            qty: curQty,
            _id: workitemData?._id
        })
            .then((data) => {
                console.log("data");
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useDebounce(() => {
        if (initialStoper2) {
            updateWorkItem({
                name: title,
                _id: workitemData?._id
            })
                .then((data) => {
                    console.log("data")
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setInitialStoper2(true)
        }
    }, 2000, [title]);

    const onNameSubmit = async (e) => {
        e.preventDefault();
        await updateWorkItem({
            name: title,
            _id: workitemData?._id
        })
            .then((data) => {
                console.log("data")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const openDetailsDialog = (type) => {
        setSelectedWorkitemData(workitemData);
        setOpenSubmittedAmountDetails(false);
        setOpenApprovedAmountDetails(false);
        setOpenGrossValueCertifiteDetails(false)
        setOpenNetCertifitePayableDetails(false)
        setOpenPaidDetails(false)

        switch (type) {
            case "submittedAmount":
                setOpenSubmittedAmountDetails(true);
                break;
            case "approvedAmount":
                setOpenApprovedAmountDetails(true);
                break;
            case "grossValueCertifite":
                setOpenGrossValueCertifiteDetails(true)
                break;
            case "netCertifitePayable":
                setOpenNetCertifitePayableDetails(true)
                break;
            case "paid":
                setOpenPaidDetails(true)
                break;
        }
    }

    useDebounce(() => {
        if (initialStoper3) {
            updateWorkItem({
                totalAmount: parseFloat(workitemData?.totalAmount || 0).toFixed(2),
                totalGST: parseFloat(workitemData?.totalGST || 0).toFixed(2),
                _id: workitemData?._id
            })
                .then((data) => {
                    console.log("data");
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setInitialStoper3(true)
        }
    }, 5000, [workitemData?.totalGST, workitemData?.totalAmount])

    const onTasksOpen = (curWorkitemData) => {
        console.log(curWorkitemData)
        let ids = parentChildrenMap?.[curWorkitemData?._id] || []
        ids.push(curWorkitemData?._id)
        let taskIds = [];
        ids.map((id) => {
            let mapperItems = idWorkitemMap?.[id]?.workItemBudgetmappings || [];
            mapperItems.map((linkedItem) => {
                if (linkedItem?.dataModel === "WBS") {
                    let locIds = linkedItem?.data || []
                    taskIds = [...taskIds, ...locIds]
                }
            })
        })
        setTaskOpenData({
            open: true,
            taskIds: taskIds
        })
    }

    return (<>
        <tr className="workItemRow" style={isCollapseChild ? { display: "none", fontWeight: "700", opacity: "1" } : { display: "table-row", fontWeight: "700", opacity: "1" }} >
            <td className={classes.itemTitleSty} >
                <div className={classes.singleItemTitleBtnContCont} >
                    {!needToMakeDisable && (
                        <Checkbox
                            color="primary"
                            checked={allSelectedIds.includes(workitemData?._id)}
                            onChange={(e) => { handleCheck(workitemData?._id, e.target.checked) }}
                        />
                    )}
                    <div className="singleItemTitleBtnCont" style={workitemData.isRateEditable ? workitemData.children ? { paddingLeft: `${paddingLeft - 15}px` } : { paddingLeft: `${paddingLeft}px` } : workitemData.children ? { paddingLeft: `${paddingLeft - 15}px`, paddingRight: "70px" } : { paddingLeft: `${paddingLeft}px`, paddingRight: "70px" }} >
                        <>
                            {workitemData?.isWorkGroup ? (<>
                                {workitemData.children ? (
                                    <IconButton
                                        style={{ padding: "4px" }}
                                        onClick={() => {
                                            setCollapseSubChild(!isCollapseSubChild)
                                        }} >
                                        <ExpandMoreIcon className={isCollapseSubChild ? classes.hideChildIcon : classes.showChildIcon} />
                                    </IconButton>
                                ) : null}
                                <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/item-group-icon.svg?alt=media" />
                                <form onSubmit={onNameSubmit} >
                                    {needToMakeDisable ? (
                                        <input
                                            type="text"
                                            placeholder="Enter item title"
                                            value={title}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="Enter item title"
                                            value={title}
                                            onChange={(e) => { setTitle(e.target.value) }}
                                            style={title && title.length > 0 ? workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" } : workitemData?.isRateEditable ? { fontWeight: "600", border: "1px solid red" } : { fontWeight: "400", border: "1px solid red" }}
                                        />
                                    )}
                                </form>
                                {workitemData?.isRateEditable && !needToMakeDisable ? (<>
                                    <IconButton className="editIconSty" onClick={() => { setOpenItemEditDialog(true) }} >
                                        <EditIcon />
                                    </IconButton>
                                    <EditWorkItem
                                        openItemEditDialog={openItemEditDialog}
                                        setOpenItemEditDialog={setOpenItemEditDialog}
                                        projectProfileId={subheadData?.profile?._id}
                                        workitemData={workitemData}
                                        libraryCategoryDictionary={libraryCategoryDictionary}
                                        setWorkItems={setWorkItems}
                                        workitems={workitems}
                                    />
                                </>) : null}
                                {!needToMakeDisable && (<>
                                    <MyPopOver
                                        closeOnClick={true}
                                        appearContent={
                                            <Button className="singleItemBtnCont" >
                                                <AddIcon style={{ marginRight: "5px", fontSize: "15px", color: "white" }} />
                                                <ExpandMoreIcon style={{ fontSize: "15px", color: "white" }} />
                                            </Button>
                                        }
                                        showContent={<div className={classes.optionsCont} >
                                            <div className={classes.singleOption} onClick={() => { createGroupItem(workitemData?._id, workitemData?.numberOfChildren, workitemData) }} >
                                                <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/item-group-icon.svg?alt=media" />
                                                <p>Item Group</p>
                                            </div>
                                            <div
                                                className={classes.singleOption}
                                                onClick={() => {
                                                    if (workitemData?.isWorkGroup) {
                                                        setOpenItemAddDialog(true)
                                                    } else {
                                                        createWorkItem(false, { isRateEditable: false, itemName: null }, false, workitemData)
                                                    }
                                                }}
                                            >
                                                <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/work-item-icon.svg?alt=media" />
                                                <p>Work Item</p>
                                            </div>

                                        </div>}
                                    />
                                    <BudgetCostAddWorkItem
                                        openItemAddDialog={openItemAddDialog}
                                        setOpenItemAddDialog={setOpenItemAddDialog}
                                        createWorkItem={createWorkItem}
                                        libraryCategoryDictionary={libraryCategoryDictionary}
                                        setLibraryCategoryDictionary={setLibraryCategoryDictionary}
                                        projectProfileId={subheadData?.profile?._id}
                                        curWorkItem={workitemData}
                                    />
                                </>)}
                            </>) : (<>
                                {workitemData.children ? (
                                    <IconButton
                                        style={{ padding: "4px" }}
                                        onClick={() => {
                                            setCollapseSubChild(!isCollapseSubChild)
                                        }}
                                    >
                                        <ExpandMoreIcon className={isCollapseSubChild ? classes.hideChildIcon : classes.showChildIcon} />
                                    </IconButton>
                                ) : null}
                                <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/work-item-icon.svg?alt=media" />
                                <form onSubmit={onNameSubmit} >
                                    {needToMakeDisable ? (
                                        <input
                                            type="text"
                                            placeholder="Enter item title"
                                            value={title}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="Enter item title"
                                            value={title}
                                            onChange={(e) => { setTitle(e.target.value) }}
                                            style={title && title.length > 0 ? workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" } : workitemData?.isRateEditable ? { fontWeight: "600", border: "1px solid red" } : { fontWeight: "400", border: "1px solid red" }}
                                        />
                                    )}
                                </form>
                                {workitemData?.isRateEditable ? (<>
                                    {!needToMakeDisable && (<>
                                        <IconButton className="editIconSty" onClick={() => { setOpenItemEditDialog(true) }} >
                                            <EditIcon />
                                        </IconButton>
                                        <EditWorkItem
                                            openItemEditDialog={openItemEditDialog}
                                            setOpenItemEditDialog={setOpenItemEditDialog}
                                            projectProfileId={subheadData?.profile?._id}
                                            workitemData={workitemData}
                                            setWorkItems={setWorkItems}
                                            libraryCategoryDictionary={libraryCategoryDictionary}
                                            workitems={workitems}
                                        />
                                    </>)}
                                </>) : null}
                                {!needToMakeDisable && (<>
                                    <Button
                                        className="singleItemBtnCont"
                                        onClick={() => {
                                            if (workitemData?.isWorkGroup) {
                                                setOpenItemAddDialog(true)
                                            } else {
                                                createWorkItem(false, { isRateEditable: false, itemName: null }, false, workitemData, null)
                                            }
                                        }}
                                    >
                                        <AddIcon style={{ fontSize: "15px", color: "white" }} />
                                    </Button>
                                    <BudgetCostAddWorkItem
                                        openItemAddDialog={openItemAddDialog}
                                        setOpenItemAddDialog={setOpenItemAddDialog}
                                        createWorkItem={createWorkItem}
                                        libraryCategoryDictionary={libraryCategoryDictionary}
                                        setLibraryCategoryDictionary={setLibraryCategoryDictionary}
                                        projectProfileId={subheadData?.profile?._id}
                                        curWorkItem={workitemData}
                                    />
                                </>)}
                            </>)}
                        </>
                    </div>
                </div>
            </td>
            <td className={classes.width140} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }} >{workitemData.itemPackage ? workitemData?.resourceType : null}</td>
            <td className={classes.width100} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }}  >{workitemData?.isWorkGroup ? null : workitemData?.unit}</td>
            <td className={classes.width200} >
                <div className={classes.linkedItemsCont} >
                    <Tooltip title={`Tasks-${workitemData?.numberOfTasks || 0}`} placement="top">
                        <IconButton onClick={() => { onTasksOpen(workitemData) }} >
                            <GoTasklist
                                className={classes.iconSty}
                                style={{ backgroundColor: "green" }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`Bill List-${workitemData?.numberOfBillLists || 0}`} placement="top">
                        <IconButton onClick={() => { onTasksOpen(workitemData) }} >
                            <RiBillLine
                                className={classes.iconSty}
                                style={{ backgroundColor: "green" }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </td>
            <td
                className={classes.width100}
                style={{ padding: "0px 5px" }}
            >
                {workitemData?.isWorkGroup ? null : (<>{workitemData?.numberOfChildren > 0 ? (
                    <span style={
                        workitemData?.isRateEditable ?
                            {
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "right",
                                fontSize: "12px",
                                paddingRight: "3px"
                            }
                            :
                            {
                                fontWeight: "400",
                                width: "100%",
                                textAlign: "right",
                                fontSize: "12px",
                                paddingRight: "3px"
                            }
                    }
                    >
                        {workitemData?.qty || 0}
                    </span>
                ) : (
                    <form onSubmit={onQtySubmit} style={{ width: "100%" }} >
                        {needToMakeDisable ? (
                            <TextFieldNumberWithoutAnyStyle
                                value={curQty}
                                disabled={true}
                            />
                        ) : (
                            <TextFieldNumberWithoutAnyStyle
                                value={curQty}
                                onChange={(e) => { setCurQty(e.target.value) }}
                                classStyle={workitemData?.isRateEditable ? classes.qtySty1 : classes.qtySty2}
                            />
                        )}
                    </form>
                )}
                </>)}
            </td>
            <td className={classes.width200} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }} >{workitemData?.isWorkGroup ? null : parseFloat(workitemData?.ratePerUnit || 0).toFixed(2)}</td>
            <td className={classes.width200} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }} >{workitemData?.isWorkGroup ? null : (parseFloat(workitemData?.ratePerUnit || 0) * curQty).toFixed(2)}</td>
            <td className={classes.width150} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }} >{parseFloat(workitemData?.totalGST || 0).toFixed(2)}</td>
            <td className={classes.width200} style={workitemData?.isRateEditable ? { fontWeight: "600" } : { fontWeight: "400" }} >{parseFloat(workitemData?.totalAmount || 0).toFixed(2)}</td>

            <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("submittedAmount") }} >{parseFloat(workitemData?.totalSubmittedAmount || 0).toFixed(2)}</td>
            <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("approvedAmount") }} >{parseFloat(workitemData?.totalApprovedAmount || 0).toFixed(2)}</td>
            <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("grossValueCertifite") }} >{parseFloat(workitemData?.totalTxInitialAmount || 0).toFixed(2)}</td>
            <td className={`${classes.width200} ${classes.clickAble}`} onClick={() => { openDetailsDialog("paid") }} >{parseFloat(workitemData?.totalPaidAmount || 0).toFixed(2)}</td>
            <td className={classes.width200} >{parseFloat(workitemData?.totalLocOutstanding || 0).toFixed(2)}</td>
            <td className={classes.width200} >{parseFloat(workitemData?.totalLocBudgetBalance || 0).toFixed(2)}</td>
        </tr>
        {workitemData.children && workitemData.children.map(child => (
            <BudgetSingleWorkItem
                key={child._id}
                needToMakeDisable={needToMakeDisable}
                workitemData={child}
                createGroupItem={createGroupItem}
                createWorkItem={createWorkItem}
                paddingLeft={paddingLeft + 25}
                isCollapseChild={isCollapseSubChild}
                setCollapseChild={setCollapseSubChild}
                workitems={workitems}
                setWorkItems={setWorkItems}
                libraryCategoryDictionary={libraryCategoryDictionary}
                setLibraryCategoryDictionary={setLibraryCategoryDictionary}
                setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
                setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
                setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
                setOpenPaidDetails={setOpenPaidDetails}
                setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
                setSelectedWorkitemData={setSelectedWorkitemData}
                handleCheck={handleCheck}
                allSelectedIds={allSelectedIds}
                setTaskOpenData={setTaskOpenData}
                parentChildrenMap={parentChildrenMap}
                idWorkitemMap={idWorkitemMap}
            />
        ))}
    </>);
};

export default BudgetSingleWorkItem;