import Cookies from "universal-cookie";
import socket from "./socket/socketio";

const cookies = new Cookies(null, { path: "/", domain: ".reallist.ai" });

export const setAuthCookieData = ({ user, token, entireState }) => {
  console.log("setAuthCookie called");
  try {
    if (user && typeof user === 'object') {
      cookies.set("reallistUser", JSON.stringify(user));
    }
    if (token && typeof token === 'string') {
      cookies.set("reallistToken", token);
    }

    // Persist state to the backend only if user and entireState are defined
    if (user?._id && entireState && typeof entireState === 'object') {
      socket.emit("persistState", { userId: user._id, state: entireState }, (response) => {
        if (response.success) {
          console.log("State persisted successfully");
        } else {
          console.error("Error persisting state:", response.error);
        }
      });
    } else {
      console.warn("Skipped state persistence due to missing data");
    }
  } catch (error) {
    console.error("Error in setAuthCookieData:", error);
  }
};

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getAuthCookieData = (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      let user;
      try {
        const userCookie = cookies.get("reallistUser");
        user = typeof userCookie === 'object' ? userCookie : JSON.parse(userCookie || "null");
      } catch (error) {
        console.error("Error parsing user cookie:", error);
        user = null;
      }
      let token = cookies.get("reallistToken");
      if (token && typeof token === 'string') {
        localStorage.setItem("token", token);
      }
      if (user && user._id) {
        // Get persisted state from the backend
        socket.emit("getPersistedState", { userId: user._id }, (response) => {
          if (response.state && typeof response.state === 'object') {

            dispatch({
              type: 'SET_ENTIRE_STATE',
              payload: response.state
            });
            resolve({ user, token, entireState: response.state });
          } else {
            console.warn("No valid persisted state found");
            dispatch({
              type: "SET_AUTH_USER",
              user,
            });
            resolve({ user, token });
          }
        });
      } else {
        console.warn("No valid user found in cookies");
        resolve({ user: null, token: null });
      }
    } catch (error) {
      console.log("Error: " + error)
    } finally {
      await delay(5000);
      window.location.reload();
    }
  });
};