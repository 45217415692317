import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, ClickAwayListener } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import NormalDialog from "../styled/CommonComponents/NormalDialog"
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import { updateWorkItemAndResources } from "./api.call";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";
import "./index.css"

const itemTypesArr = ["Material", "Material + Labour", "Labour", "Machinery & Equipments", "Services", "Subcontract"]
const itemTypesForResourcesArr = ["Material", "Material + Labour", "Labour", "Machinery & Equipments", "Contractor Add-Ons"]
const itemTypesForResourcesValArr = ["material", "materialAndLabour", "labour", "machineryAndEquipments", "contractorAddOns"]
const keyValuePair = { material: "Material", materialAndLabour: "Material + Labour", labour: "Labour", machineryAndEquipments: "Machinery & Equipments", contractorAddOns: "Contractor Add-Ons" }
const alp = ["A", "B", "C", "D", "E"]
const rateType = ["Direct Rate", "Rate Analysis"]
const adOnTypesArr = ["Tax", "Other"]

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    itemDetailsAndBtnCont: {
        width: "100%",
        height: "100%",
        padding: "0px 10px",
    },
    itemDetailsCont: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        paddingBottom: "50px",
        paddingTop: "10px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    titleAndBtnCont: {
        width: "100%",
        height: "50px",
        marginBottom: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    categorySubcategoryCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "30px"
    },
    categoryCont: {
        width: "49%",
        position: "relative",
    },
    subcategoryCont: {
        width: "49%",
        position: "relative",
    },
    suggestionCont: {
        position: "absolute",
        top: "43px",
        left: "0px",
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
        zIndex: "9",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
    },
    nameRateTypeItemTypeCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "30px"
    },
    itemNameCont: {
        width: "49%"
    },
    rateTypeCont: {
        width: "24%"
    },
    itemTypeCont: {
        width: "24%"
    },
    singleSuggetion: {
        width: "100%",
        padding: "5px",
        fontSize: "13px",
        fontWeight: "400",
        color: "gray",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    workItemTitle: {
        fontSize: "25px",
        fontWeight: "600",
    },
    qtyUnitRateCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px"
    },
    qtyCont: {
        width: "200px",
        marginRight: "10px"
    },
    unitCont: {
        width: "200px",
        marginRight: "10px"
    },
    rateCont: {
        width: "200px",
        marginRight: "10px"
    },
    tableSty: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        marginTop: "40px",
        "& tr": {
            height: "35px"
        },
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right"
        }
    },
    resourcesTableSty: {

    },
    qtyTableSty: {
        width: "75px"
    },
    qtyWastageTableSty: {
        width: "90px"
    },
    qtyTotalTableSty: {
        width: "110px"
    },
    unitTableSty: {
        width: "100px"
    },
    rateTableSty: {
        width: "95px"
    },
    taxTableSty: {
        width: "75px"
    },
    rateTaxTableSty: {
        width: "120px"
    },
    totalTableSty: {
        width: "190px"
    },
    lableBtnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "space-between",
        "& p": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& .MuiButton-root": {
            minWidth: "50px"
        },
        "& .MuiButton-label": {
            fontSize: "10px",
            color: "white",
        },
        "& .MuiButton-root:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    btnCont: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        padding: "4px",
        borderRadius: "10px",
        marginLeft: "10px"
    },
    typeSingleOptionSty: {
        padding: "5px 7px",
        fontSize: "15px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    optionsCont: {
        padding: "5px"
    },
    numberInputSty: {
        border: "none",
        textAlign: "right"
    },
    textInputSty: {
        border: "none",
        fontSize: "15px",
        fontWeight: "500",
        paddingLeft: "5px"
    },
    errorMsg: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        textAlign: "left",
        paddingTop: "0px",
        height: "5px"
    },
    tableErrMsg: {
        padding: "0px",
        margin: "0px",
        fontSize: "10px",
        fontWeight: "400",
        color: "red",
        height: "12px",
        textAlign: "left",
        paddingLeft: "7px"
    },
    subheaderCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiIconButton-root": {
            padding: "0px",
            marginLeft: "10px"
        },
        "& .MuiIconButton-label": {
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main
        }
    },
    totalDeleteCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& p": {
            marginRight: "3px"
        }
    }
}));

const EditWorkItem = ({
    openItemEditDialog, setOpenItemEditDialog, libraryCategoryDictionary,
    projectProfileId, workitemData, workitems, setWorkItems
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    //main edit body useState
    const [allCategoriesArr, setAllCategoriesArr] = useState([])
    const [openCategorySuggestion, setOpenCategorySuggestion] = useState(false)
    const [openSubcategorySuggestion, setOpenSubcategorySuggestion] = useState(false)
    const [suggestedCategories, setSuggestedCategories] = useState([])
    const [suggestedSubcategories, setSuggestedSubcategories] = useState([])
    const [category, setCategory] = useState("")
    const [subcategory, setSubcategory] = useState("")
    const [categoryObj, setCategoryObj] = useState(null)
    const [subcategoryObj, setSubcategoryObj] = useState(null)
    const [itemName, setItemName] = useState("")
    const [rateTypeValue, setRateTypeValue] = useState("Unit Rate Analysis")
    const [itemTypeValue, setItemTypeValue] = useState("Material + Labour")
    const [forQty, setForQty] = useState(1)
    const [unitValue, setUnitValue] = useState(null)
    const [allOverTaxInParcentage, setAllOverTaxInParcentage] = useState(0)
    const [allOverRateIncludingTax, setAllOverRateIncludingTax] = useState(0)
    const [materialArrObj, setMaterialArrObj] = useState([])
    const [materialAndLabourArrObj, setMaterialAndLabourArrObj] = useState([])
    const [labourArrObj, setLabourArrObj] = useState([])
    const [machineryAndEquipmentsArrObj, setMachineryAndEquipmentsArrObj] = useState([])
    const [contractorAddOnsArr, setContractorAddOnsArr] = useState([])
    const [allItemsDetailsObjArr, setAllItemsDetailsObjArr] = useState([])
    const [totalOfAllItems, setTotalOfAllItems] = useState(0);
    const [totalAmountWithoutAdOn, setTotalAmountWithoutAdOn] = useState(0);
    const [totalTaxAmount, setTotalTaxAmount] = useState(0);
    const [note, setNote] = useState("");
    const [totalOfAllItemsAlphabet, setTotalOfAllItemsAlphabet] = useState([]);
    const [totalAmountWithoutAdOnAlphabet, setTotalAmountWithoutAdOnAlphabet] = useState([]);
    const [showErrors, setShowErrors] = useState(false)
    const [editLoading, setEditLoading] = useState(false)

    useEffect(() => {
        let curData = null;
        libraryCategoryDictionary && libraryCategoryDictionary.map((s) => {
            if (s?._id == workitemData?.codeLibrary?._id) {
                curData = s;
            }
        })
        if (curData?.categories && curData.categories.length > 0) {
            setAllCategoriesArr(curData?.categories || []);
            setSuggestedCategories(curData?.categories || []);
            let selectedCategoryObj = null;
            curData?.categories && curData.categories.map((k) => {
                if (k?.category?._id === workitemData?.categoryId) {
                    setCategoryObj(k);
                    selectedCategoryObj = k;
                }
            })
            if (selectedCategoryObj?.subCategories && selectedCategoryObj.subCategories.length > 0) {
                setSuggestedSubcategories(selectedCategoryObj?.subCategories);
                selectedCategoryObj?.subCategories && selectedCategoryObj?.subCategories.map((k) => {
                    if (k?._id === workitemData?.subCategoryId) {
                        setSubcategoryObj(k);
                    }
                })
            } else {
                setSubcategoryObj(null);
                setSuggestedSubcategories([]);
            }
        } else {
            setAllCategoriesArr([]);
            setSuggestedCategories([]);
            setCategoryObj(null);
        }
    }, [libraryCategoryDictionary, workitemData, openItemEditDialog])

    useEffect(() => {
        let locMaterialArrObj = []
        let locMaterialAndLabourArrObj = []
        let locLabourArrObj = []
        let locMachineryAndEquipmentsArrObj = []
        let locContractorAddOnsArr = []
        let locResources = workitemData?.resources || []
        let adOns = workitemData?.adOns || []
        let locTotalAmountForAdOn = 0;

        locResources.map((resourceData) => {
            let recQty = resourceData?.quantity || 0
            let recQtyWastage = 0;
            let recTotalQty = resourceData?.quantityWithWastage || 0
            let locRateIncludingTax = 0;
            let locTotalCostIncludingTax = 0;
            if (recTotalQty === 0) {
                recQtyWastage = 0;
                recTotalQty = recQty;
            } else if (recTotalQty === recQty) {
                recQtyWastage = 0;
            } else {
                recQtyWastage = parseFloat((parseFloat(recTotalQty - recQty) / recQty) * 100);
            }
            locRateIncludingTax = parseFloat(resourceData?.resource?.purchasePrice || 0) + parseFloat((parseFloat(resourceData?.resource?.purchasePrice || 0) * parseFloat(resourceData?.resource?.tax || 0)) / 100)
            locTotalCostIncludingTax = locRateIncludingTax * recTotalQty
            locTotalAmountForAdOn = locTotalAmountForAdOn + locTotalCostIncludingTax;

            const newObj = {
                resource_id: resourceData?.resource?._id,
                name: resourceData?.resource?.name || "",
                qty: parseFloat(recQty || 0).toFixed(2),
                qtyWastage: parseFloat(recQtyWastage || 0).toFixed(2),
                totalQty: parseFloat(recTotalQty || 0).toFixed(2),
                unit: resourceData?.resource?.purchaseUnit || "",
                price: parseFloat(resourceData?.resource?.purchasePrice || 0).toFixed(2),
                tax: parseFloat(resourceData?.resource?.tax || 0).toFixed(2),
                rateIncludingTax: parseFloat(locRateIncludingTax || 0).toFixed(2),
                totalCostIncludingTax: parseFloat(locTotalCostIncludingTax).toFixed(2),
            }

            if (resourceData?.resource?.model === "Material") {
                locMaterialArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Material + Labour") {
                locMaterialAndLabourArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Labour") {
                locLabourArrObj.push(newObj)
            }
            if (resourceData?.resource?.model === "Machinery & Equipments") {
                locMachineryAndEquipmentsArrObj.push(newObj)
            }
        })

        adOns.map((adOn) => {
            let adOnTotalAmount = 0;
            if (adOn?.rateType == "Rate in %") {
                adOnTotalAmount = parseFloat((parseFloat(locTotalAmountForAdOn || 0) * parseFloat(adOn?.value || 0)) / 100).toFixed(2);
            } else {
                adOnTotalAmount = parseFloat(adOn?.value || 0).toFixed(2);
            }

            locContractorAddOnsArr.push({
                name: adOn?.name || "",
                adOnType: adOn?.type || "Other",
                rateType: adOn?.rateType || "Rate in %",
                value: parseFloat(adOn?.value || 0).toFixed(2),
                total: adOnTotalAmount
            })
        })

        setMaterialArrObj(locMaterialArrObj)
        setMaterialAndLabourArrObj(locMaterialAndLabourArrObj)
        setLabourArrObj(locLabourArrObj)
        setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj)
        setContractorAddOnsArr(locContractorAddOnsArr)
        setCategory(workitemData?.category || "")
        setSubcategory(workitemData?.subCategory || "")
        setItemName(workitemData?.name || "")
        setRateTypeValue(workitemData?.rateType || "")
        setItemTypeValue(workitemData?.resourceType || "")
        setForQty(workitemData?.forQty || 1)
        setUnitValue(workitemData?.unit || "No.")
    }, [workitemData, openItemEditDialog])

    useDebounce(() => {
        let filteredCategories = []
        let exactMatch = null;
        allCategoriesArr.map((categoryData) => {
            const patt = new RegExp(category, "i");
            const res = patt.test(categoryData?.category?.name);
            if (res) {
                filteredCategories.push(categoryData);
            }
            if (category === categoryData?.category?.name) {
                exactMatch = categoryData;
            }
        });
        setCategoryObj(exactMatch)
        setSuggestedCategories(filteredCategories)
    }, 500, [category, allCategoriesArr])

    useDebounce(() => {
        if (categoryObj?.category?._id) {
            let exactMatch = null;
            let filteredSubcategories = []
            categoryObj?.subCategories.map((subcategoryData) => {
                const patt = new RegExp(subcategory, "i");
                const res = patt.test(subcategoryData?.name);
                if (res) {
                    filteredSubcategories.push(subcategoryData);
                }
                if (subcategory === subcategoryData?.name) {
                    exactMatch = subcategoryData;
                }
            });
            setSubcategoryObj(exactMatch)
            setSuggestedSubcategories(filteredSubcategories)
        }
    }, 500, [subcategory])

    const addResource = (type) => {
        const otherObj = {
            name: "",
            qty: 0,
            qtyWastage: 0,
            totalQty: 0,
            unit: "No.",
            price: 0,
            tax: 0,
            rateIncludingTax: 0,
            totalCostIncludingTax: 0,
        }
        const addOnObj = {
            name: "",
            adOnType: "Other",
            rateType: "Rate in %",
            value: 0,
            total: 0
        }

        switch (type) {
            case "material":
                setMaterialArrObj([...materialArrObj, otherObj]);
                break;

            case "materialAndLabour":
                setMaterialAndLabourArrObj([...materialAndLabourArrObj, otherObj]);
                break;

            case "labour":
                setLabourArrObj([...labourArrObj, otherObj]);
                break;

            case "machineryAndEquipments":
                setMachineryAndEquipmentsArrObj([...machineryAndEquipmentsArrObj, otherObj]);
                break;

            case "contractorAddOns":
                setContractorAddOnsArr([...contractorAddOnsArr, addOnObj]);
                break;
        }
    }

    const changeResourceName = (type, index, value) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locMaterialArrObj[index].name = value;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locMaterialAndLabourArrObj[index].name = value;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locLabourArrObj[index].name = value;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locMachineryAndEquipmentsArrObj[index].name = value;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceQty = (type, index, value = 0) => {
        let locQty = 0;
        let locQtyWastage = 0;
        let locQtyTotal = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMaterialArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialArrObj[index].qty = locQty;
                locMaterialArrObj[index].qtyWastage = locQtyWastage;
                locMaterialArrObj[index].totalQty = locQtyTotal;
                locMaterialArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMaterialAndLabourArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialAndLabourArrObj[index].qty = locQty;
                locMaterialAndLabourArrObj[index].qtyWastage = locQtyWastage;
                locMaterialAndLabourArrObj[index].totalQty = locQtyTotal;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialAndLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locLabourArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locLabourArrObj[index].qty = locQty;
                locLabourArrObj[index].qtyWastage = locQtyWastage;
                locLabourArrObj[index].totalQty = locQtyTotal;
                locLabourArrObj[index].totalCostIncludingTax = (parseFloat(locLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locQty = parseFloat(value || 0);
                locQtyWastage = parseFloat(locMachineryAndEquipmentsArrObj[index]?.qtyWastage || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMachineryAndEquipmentsArrObj[index].qty = locQty;
                locMachineryAndEquipmentsArrObj[index].qtyWastage = locQtyWastage;
                locMachineryAndEquipmentsArrObj[index].totalQty = locQtyTotal;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = (parseFloat(locMachineryAndEquipmentsArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceQtyWastage = (type, index, value = 0) => {
        let locQty = 0;
        let locQtyWastage = 0;
        let locQtyTotal = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locMaterialArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialArrObj[index].qtyWastage = locQtyWastage;
                locMaterialArrObj[index].totalQty = locQtyTotal;
                locMaterialArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locMaterialAndLabourArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMaterialAndLabourArrObj[index].qtyWastage = locQtyWastage;
                locMaterialAndLabourArrObj[index].totalQty = locQtyTotal;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = (parseFloat(locMaterialAndLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locQtyWastage = parseFloat(value || 0);
                locQty = parseFloat(locLabourArrObj[index]?.qty || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locLabourArrObj[index].qtyWastage = locQtyWastage;
                locLabourArrObj[index].totalQty = locQtyTotal;
                locLabourArrObj[index].totalCostIncludingTax = (parseFloat(locLabourArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locQty = parseFloat(locMachineryAndEquipmentsArrObj[index]?.qty || 0);
                locQtyWastage = parseFloat(value || 0);
                locQtyTotal = parseFloat(locQty + ((locQty * locQtyWastage) / 100))
                locMachineryAndEquipmentsArrObj[index].qtyWastage = locQtyWastage;
                locMachineryAndEquipmentsArrObj[index].totalQty = locQtyTotal;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = (parseFloat(locMachineryAndEquipmentsArrObj[index]?.rateIncludingTax || 0) * parseFloat(locQtyTotal || 0));
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceUnit = (type, index, value) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                locMaterialArrObj[index].unit = value;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                locMaterialAndLabourArrObj[index].unit = value;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                locLabourArrObj[index].unit = value;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                locMachineryAndEquipmentsArrObj[index].unit = value;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceRate = (type, index, value) => {
        let tax = 0;
        let amount = 0;
        let rateWithTax = 0;
        let qty = 0;
        let totalAmount = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                tax = parseFloat(locMaterialArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialArrObj[index].price = value;
                locMaterialArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                tax = parseFloat(locMaterialAndLabourArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialAndLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialAndLabourArrObj[index].price = value;
                locMaterialAndLabourArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                tax = parseFloat(locLabourArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locLabourArrObj[index].price = value;
                locLabourArrObj[index].rateIncludingTax = rateWithTax;
                locLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                tax = parseFloat(locMachineryAndEquipmentsArrObj[index].tax);
                amount = parseFloat(value);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMachineryAndEquipmentsArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMachineryAndEquipmentsArrObj[index].price = value;
                locMachineryAndEquipmentsArrObj[index].rateIncludingTax = rateWithTax;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = totalAmount;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    const changeResourceTax = (type, index, value) => {
        let tax = 0;
        let amount = 0;
        let rateWithTax = 0;
        let qty = 0;
        let totalAmount = 0;
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMaterialArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialArrObj[index].tax = value;
                locMaterialArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialArrObj(locMaterialArrObj);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMaterialAndLabourArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMaterialAndLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMaterialAndLabourArrObj[index].tax = value;
                locMaterialAndLabourArrObj[index].rateIncludingTax = rateWithTax;
                locMaterialAndLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setMaterialAndLabourArrObj(locMaterialAndLabourArrObj);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locLabourArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locLabourArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locLabourArrObj[index].tax = value;
                locLabourArrObj[index].rateIncludingTax = rateWithTax;
                locLabourArrObj[index].totalCostIncludingTax = totalAmount;
                setLabourArrObj(locLabourArrObj);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj];
                tax = parseFloat(value);
                amount = parseFloat(locMachineryAndEquipmentsArrObj[index]?.price || 0);
                rateWithTax = parseFloat((amount * tax) / 100) + amount;
                qty = parseFloat(locMachineryAndEquipmentsArrObj[index].totalQty);
                totalAmount = rateWithTax * qty;
                locMachineryAndEquipmentsArrObj[index].tax = value;
                locMachineryAndEquipmentsArrObj[index].rateIncludingTax = rateWithTax;
                locMachineryAndEquipmentsArrObj[index].totalCostIncludingTax = totalAmount;
                setMachineryAndEquipmentsArrObj(locMachineryAndEquipmentsArrObj);
                break;
        }
    }

    useEffect(() => {
        let locAllArr = [];
        let serialNo = 0;
        let locOtherItemsTotalAmount = 0;
        let locAllItemTotalAmount = 0;
        let locTotalTaxAmount = 0;
        let locTotalOfAllItemsAlphabets = [];
        let locTotalAmountWithoutAdOnAlphabets = [];

        if (materialArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            materialArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "material",
                arr: materialArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (materialAndLabourArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            materialAndLabourArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0);
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "materialAndLabour",
                arr: materialAndLabourArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (labourArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            labourArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "labour",
                arr: labourArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        if (machineryAndEquipmentsArrObj.length > 0) {
            let totalAmount = 0;
            let innerTotalTaxAmount = 0;
            machineryAndEquipmentsArrObj.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.totalCostIncludingTax || 0)
                innerTotalTaxAmount = innerTotalTaxAmount + parseFloat((parseFloat(dataObj?.rateIncludingTax || 0) - parseFloat(dataObj?.price || 0)) * parseFloat(dataObj?.totalQty || 0))
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "machineryAndEquipments",
                arr: machineryAndEquipmentsArrObj,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locOtherItemsTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalTaxAmount = locTotalTaxAmount + innerTotalTaxAmount;
            locTotalAmountWithoutAdOnAlphabets.push(alp[serialNo]);
            serialNo = serialNo + 1;
        }

        locTotalOfAllItemsAlphabets = [...locTotalAmountWithoutAdOnAlphabets]
        locAllItemTotalAmount = locOtherItemsTotalAmount;
        if (contractorAddOnsArr.length > 0) {
            let totalAmount = 0;
            contractorAddOnsArr.map((dataObj) => {
                totalAmount = totalAmount + parseFloat(dataObj?.total || 0)
            })

            locAllArr.push({
                alphabet: alp[serialNo],
                name: "contractorAddOns",
                arr: contractorAddOnsArr,
                totalAmount: parseFloat(totalAmount || 0).toFixed(2)
            })
            locAllItemTotalAmount = locOtherItemsTotalAmount + totalAmount;
            locTotalOfAllItemsAlphabets.push(alp[serialNo]);
        }

        setTotalOfAllItemsAlphabet(locTotalOfAllItemsAlphabets)
        setTotalAmountWithoutAdOnAlphabet(locTotalAmountWithoutAdOnAlphabets)
        setTotalTaxAmount(locTotalTaxAmount.toFixed(2))
        setTotalOfAllItems(locAllItemTotalAmount.toFixed(2))
        setTotalAmountWithoutAdOn(locOtherItemsTotalAmount.toFixed(2))
        setAllItemsDetailsObjArr(locAllArr)
    }, [materialArrObj, materialAndLabourArrObj, labourArrObj,
        machineryAndEquipmentsArrObj, contractorAddOnsArr])

    useDebounce(() => {
        let locContractorAddOnsArr = [];
        contractorAddOnsArr.map((contractorAddOnData) => {
            locContractorAddOnsArr.push({
                name: contractorAddOnData?.name || "",
                adOnType: contractorAddOnData?.adOnType || "",
                rateType: contractorAddOnData?.rateType || "",
                value: contractorAddOnData?.value || 0,
                total: ((parseFloat(totalAmountWithoutAdOn || 0) * parseFloat(contractorAddOnData?.value || 0)) / 100).toFixed(2)
            })
        })
        setContractorAddOnsArr(locContractorAddOnsArr)
    }, 700, [totalAmountWithoutAdOn])

    useDebounce(() => {
        if (showErrors) {
            setShowErrors(false)
        }
    }, 500, [materialArrObj, materialAndLabourArrObj, labourArrObj,
        allItemsDetailsObjArr, machineryAndEquipmentsArrObj,
        contractorAddOnsArr, category, subcategory, itemName,
        rateTypeValue, itemTypeValue])

    const deleteResource = (type, index) => {
        switch (type) {
            case "material":
                let locMaterialArrObj = [...materialArrObj]
                locMaterialArrObj.splice(index, 1)
                setMaterialArrObj([...locMaterialArrObj]);
                break;

            case "materialAndLabour":
                let locMaterialAndLabourArrObj = [...materialAndLabourArrObj]
                locMaterialAndLabourArrObj.splice(index, 1)
                setMaterialAndLabourArrObj([...locMaterialAndLabourArrObj]);
                break;

            case "labour":
                let locLabourArrObj = [...labourArrObj]
                locLabourArrObj.splice(index, 1)
                setLabourArrObj([...locLabourArrObj]);
                break;

            case "machineryAndEquipments":
                let locMachineryAndEquipmentsArrObj = [...machineryAndEquipmentsArrObj]
                locMachineryAndEquipmentsArrObj.splice(index, 1)
                setMachineryAndEquipmentsArrObj([...locMachineryAndEquipmentsArrObj]);
                break;

            case "contractorAddOns":
                let locContractorAddOnsArr = [...contractorAddOnsArr]
                locContractorAddOnsArr.splice(index, 1)
                setContractorAddOnsArr([...locContractorAddOnsArr]);
                break;
        }
    }

    const submitUpdate = async () => {
        let isNotValid = false;
        if (!(category && category.length > 0)) {
            isNotValid = true;
        }
        if (!(subcategory && subcategory.length > 0)) {
            isNotValid = true;
        }
        if (!(itemName && itemName.length > 0)) {
            isNotValid = true;
        }
        if (!(rateTypeValue && rateTypeValue.length > 0)) {
            isNotValid = true;
        }
        if (!(itemTypeValue && itemTypeValue.length > 0)) {
            isNotValid = true;
        }
        let totalAmountWithoutTax = 0;
        allItemsDetailsObjArr && allItemsDetailsObjArr.map((itemsDetailsObj) => {
            let arr = itemsDetailsObj?.arr || []
            let innerTotalAmountWithoutTax = 0;

            if (itemsDetailsObj?.name === "contractorAddOns") {
                arr.map((singleData) => {
                    if (!(singleData?.name && singleData.name.length > 0)) {
                        isNotValid = true;
                    }
                    innerTotalAmountWithoutTax = parseFloat(innerTotalAmountWithoutTax) + parseFloat(singleData?.total || 0);
                })
            } else {
                arr.map((singleData) => {
                    if (!(singleData?.name && singleData.name.length > 0)) {
                        isNotValid = true;
                    }
                    let locTotal = (parseFloat(singleData?.totalQty) * parseFloat(singleData?.price)).toFixed(2);
                    innerTotalAmountWithoutTax = parseFloat(innerTotalAmountWithoutTax) + parseFloat(locTotal);
                })
            }
            totalAmountWithoutTax = parseFloat(totalAmountWithoutTax) + parseFloat(innerTotalAmountWithoutTax);
        })

        if (isNotValid) {
            setShowErrors(true)
        } else {
            setEditLoading(true)
            let isNeedToChangeChildrens = false;
            let locName = itemName;
            let locCategory = category;
            let locSubCategory = subcategory
            let locResourceType = itemTypeValue
            let locRate = totalOfAllItems
            let locRateType = rateTypeValue
            let locUnit = unitValue
            let locQuantity = forQty
            let locRatePerUnit = 0;
            let locRatePerUnitWithTax = 0;
            let locTaxPerUnit = 0;

            if (rateTypeValue === "Direct Rate") {
                let locTotalTaxDR = parseFloat((parseFloat(totalOfAllItems) * parseFloat(allOverTaxInParcentage)) / 100)
                locRatePerUnit = (parseFloat(totalOfAllItems) / parseFloat(forQty)).toFixed(2)
                locRatePerUnitWithTax = (parseFloat(allOverRateIncludingTax) / parseFloat(forQty)).toFixed(2)
                locTaxPerUnit = (parseFloat(locTotalTaxDR) / parseFloat(forQty)).toFixed(2)
            } else {
                locRatePerUnit = (parseFloat(totalAmountWithoutTax) / parseFloat(forQty)).toFixed(2)
                locRatePerUnitWithTax = (parseFloat(totalOfAllItems) / parseFloat(forQty)).toFixed(2)
                locTaxPerUnit = (parseFloat(totalTaxAmount) / parseFloat(forQty)).toFixed(2)
            }

            if (locCategory != workitemData?.category || locSubCategory != workitemData?.subCategory ||
                locResourceType != workitemData?.resourceType || locRate != workitemData?.rate ||
                locRateType != workitemData?.rateType || locUnit != workitemData?.unit ||
                locQuantity != workitemData?.forQty || locRatePerUnit != workitemData?.ratePerUnit ||
                locRatePerUnitWithTax != workitemData?.ratePerUnitWithTax || locTaxPerUnit != workitemData?.taxPerUnit) {
                isNeedToChangeChildrens = true;
            }
            let allChildrenIds = [];

            workitems && workitems.map((workitem) => {
                let ancestor = workitem?.ancestors || [];
                let _id = workitemData?._id;
                if (ancestor.includes(_id)) {
                    allChildrenIds.push(workitem?._id);
                }
            })

            let oldResorceIds = []
            let curResorceIds = []
            let deleteableIds = []
            workitemData?.resources && workitemData.resources.map((resource) => {
                if (resource?.resource?._id) {
                    oldResorceIds.push(resource?.resource?._id)
                }
            })
            allItemsDetailsObjArr.map((itemsDetailObj) => {
                let arr = itemsDetailObj?.arr || []
                if (itemsDetailObj?.name !== "contractorAddOns") {
                    arr.map((resource) => {
                        if (resource?.resource_id) {
                            curResorceIds.push(resource?.resource_id)
                        }
                    })
                }
            })
            for (let i = 0; i < oldResorceIds.length; i++) {
                if (!curResorceIds.includes(oldResorceIds[i])) {
                    deleteableIds.push(oldResorceIds[i]);
                }
            }

            const updateObj = {
                _id: workitemData?._id,
                locName,
                locCategory,
                locSubCategory,
                categoryObj: categoryObj?.category,
                subcategoryId: subcategoryObj?._id,
                locResourceType,
                locRate,
                locRateType,
                locUnit,
                locQuantity,
                locRatePerUnit,
                locRatePerUnitWithTax,
                locTaxPerUnit,
                allItemsDetailsObjArr,
                isNeedToChangeChildrens,
                allChildrenIds,
                deleteableIds,
                libraryId: workitemData?.codeLibrary?._id,
                libraryName: workitemData?.libraryLable,
                projectProfileId: workitemData?.profile,
                userId: user?._id
            }

            await updateWorkItemAndResources(updateObj)
                .then((data) => {
                    let allWorkItemIds = data.allWorkItemIds
                    let idWorkItemMap = data.idWorkItemMap
                    let updatedItemsArr = []

                    workitems.map((itemData) => {
                        let id = itemData._id;
                        if (allWorkItemIds.includes(id)) {
                            updatedItemsArr.push(idWorkItemMap[id])
                        } else {
                            updatedItemsArr.push(itemData)
                        }
                    })
                    setWorkItems(updatedItemsArr)
                    setEditLoading(false)
                    setOpenItemEditDialog(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <NormalDrawer
            openDrawer={openItemEditDialog}
            setOpenDrawer={setOpenItemEditDialog}
            anchor={"right"}
            title={"Edit Work Item"}
            width={"100vw"}
            content={<div className={classes.mainCont} >
                {openItemEditDialog && (<>
                    <div style={{ width: "100%", height: "4px" }} >
                        {editLoading && (<LinearProgress />)}
                    </div>
                    <div className={classes.bodyCont} >
                        <div className={classes.itemDetailsAndBtnCont} >
                            <div className={classes.titleAndBtnCont} >
                                <div className={classes.subheaderCont} >
                                    <h3 className={classes.workItemTitle} >Work item</h3>
                                </div>
                                <Button
                                    color='primary'
                                    variant="contained"
                                    onClick={submitUpdate}
                                    disabled={editLoading}
                                >
                                    update
                                </Button>
                            </div>
                            <div className={classes.itemDetailsCont} >
                                <div className={classes.categorySubcategoryCont} >
                                    <div className={classes.categoryCont} >
                                        <ClickAwayListener onClickAway={() => { setOpenCategorySuggestion(false) }}>
                                            <TextField
                                                variant="outlined"
                                                value={category}
                                                onChange={(e) => {
                                                    setCategory(e.target.value);
                                                    setCategoryObj(null)
                                                }}
                                                fullWidth
                                                placeholder={"Enter category"}
                                                label={"Category"}
                                                size="small"
                                                onClick={() => { setOpenCategorySuggestion(true) }}
                                            />
                                        </ClickAwayListener>
                                        {openCategorySuggestion && (
                                            <div className={classes.suggestionCont} >
                                                {suggestedCategories && suggestedCategories.map((suggestedcategoryData, i) => (
                                                    <div
                                                        key={i}
                                                        className={classes.singleSuggetion}
                                                        onClick={() => {
                                                            setCategory(suggestedcategoryData?.category?.name)
                                                            setCategoryObj(suggestedcategoryData)
                                                            setSuggestedSubcategories(suggestedcategoryData?.subCategories)
                                                        }}
                                                    >
                                                        {suggestedcategoryData?.category?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={classes.errorMsg} >
                                            {category && category.length > 1 ? null : showErrors ? "Category is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.subcategoryCont} >
                                        <ClickAwayListener onClickAway={() => { setOpenSubcategorySuggestion(false) }}>
                                            <TextField
                                                variant="outlined"
                                                value={subcategory}
                                                onChange={(e) => { setSubcategory(e.target.value); }}
                                                fullWidth
                                                placeholder={"Enter subcategory"}
                                                label={"Subcategory"}
                                                size="small"
                                                onClick={() => { setOpenSubcategorySuggestion(true) }}
                                            />
                                        </ClickAwayListener>
                                        {openSubcategorySuggestion && (
                                            <div className={classes.suggestionCont} >
                                                {suggestedSubcategories && suggestedSubcategories.map((suggestedSubcategoryData, i) => (
                                                    <div
                                                        key={i}
                                                        className={classes.singleSuggetion}
                                                        onClick={() => {
                                                            setSubcategory(suggestedSubcategoryData?.name)
                                                            setSubcategoryObj(suggestedSubcategoryData)
                                                        }}
                                                    >
                                                        {suggestedSubcategoryData?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className={classes.errorMsg} >
                                            {subcategory && subcategory.length > 1 ? null : showErrors ? "Subcategory is required" : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.nameRateTypeItemTypeCont} >
                                    <div className={classes.itemNameCont} >
                                        <TextField
                                            variant="outlined"
                                            value={itemName}
                                            fullWidth
                                            onChange={(e) => { setItemName(e.target.value); }}
                                            placeholder={"Enter item name"}
                                            label={"Item Name"}
                                            size="small"
                                        />
                                        <div className={classes.errorMsg} >
                                            {itemName && itemName.length > 1 ? null : showErrors ? "Item name is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.rateTypeCont} >
                                        <Autocomplete
                                            value={rateTypeValue}
                                            options={rateType}
                                            getOptionLabel={(option) => { return option || "" }}
                                            getOptionSelected={(option) => {
                                                return option === rateTypeValue
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Select rate type"
                                                    label={"Rate Type"}
                                                />
                                            )}
                                            onChange={(e, value) => { setRateTypeValue(value) }}
                                            size="small"
                                            fullWidth
                                        />
                                        <div className={classes.errorMsg} >
                                            {rateTypeValue && rateTypeValue.length > 1 ? null : showErrors ? "Rate type is required" : null}
                                        </div>
                                    </div>
                                    <div className={classes.itemTypeCont} >
                                        <Autocomplete
                                            value={itemTypeValue}
                                            options={itemTypesArr}
                                            getOptionLabel={(option) => { return option || "" }}
                                            getOptionSelected={(option) => {
                                                return option === itemTypeValue
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Select item type"
                                                    label={"Item Type"}
                                                />
                                            )}
                                            onChange={(e, value) => { setItemTypeValue(value) }}
                                            size="small"
                                            fullWidth
                                        />
                                        <div className={classes.errorMsg} >
                                            {itemTypeValue && itemTypeValue.length > 1 ? null : showErrors ? "Item type is required" : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.qtyUnitRateCont} >
                                    <TextField
                                        variant="outlined"
                                        value={forQty}
                                        onChange={(e) => { setForQty(e.target.value); }}
                                        placeholder={"Quantity"}
                                        label={"For Quantity"}
                                        size="small"
                                        type="number"
                                        className={classes.qtyCont}
                                    />
                                    <UnitAutocomplete
                                        fullWidth={true}
                                        value={unitValue}
                                        onChange={(e, value) => { setUnitValue(value) }}
                                        className={classes.unitCont}
                                    />
                                    <TextField
                                        variant="outlined"
                                        value={totalOfAllItems}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            setTotalOfAllItems(val);
                                            let locRateIncludingTax = parseFloat(val) + parseFloat((val * allOverTaxInParcentage) / 100)
                                            setAllOverRateIncludingTax(locRateIncludingTax.toFixed(2))
                                        }}
                                        placeholder={"Enter rate"}
                                        label={"Rate"}
                                        size="small"
                                        type="number"
                                        className={classes.rateCont}
                                        disabled={rateTypeValue === "Direct Rate" ? false : true}
                                    />
                                    {rateTypeValue === "Direct Rate" ? (<>
                                        <TextField
                                            variant="outlined"
                                            value={allOverTaxInParcentage}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                setAllOverTaxInParcentage(val);
                                                let locRateIncludingTax = parseFloat(totalOfAllItems) + parseFloat((val * totalOfAllItems) / 100)
                                                setAllOverRateIncludingTax(locRateIncludingTax.toFixed(2))
                                            }}
                                            placeholder={"Tax parcentage"}
                                            label={"Tax in %"}
                                            size="small"
                                            type="number"
                                            className={classes.rateCont}
                                        />
                                        <TextField
                                            variant="outlined"
                                            value={allOverRateIncludingTax}
                                            placeholder={"Rate including tax"}
                                            label={"Rate including tax"}
                                            size="small"
                                            type="number"
                                            className={classes.rateCont}
                                            disabled
                                        />
                                    </>) : null}
                                </div>
                                {rateTypeValue === "Direct Rate" ? null : (
                                    <table className={classes.tableSty} >
                                        <thead>
                                            <tr>
                                                <th className={classes.resourcesTableSty} >
                                                    <div className={classes.lableBtnCont} >
                                                        <p>Resources</p>
                                                        <MyPopOver
                                                            closeOnClick={true}
                                                            appearContent={
                                                                <Button className={classes.btnCont} >
                                                                    <AddIcon style={{ marginRight: "5px", fontSize: "15px", color: "white" }} />
                                                                    <ExpandMoreIcon style={{ fontSize: "15px", color: "white" }} />
                                                                </Button>
                                                            }
                                                            showContent={<div className={classes.optionsCont} >
                                                                {itemTypesForResourcesArr && itemTypesForResourcesArr.map((itemType, i) => (
                                                                    <div
                                                                        key={i}
                                                                        className={classes.typeSingleOptionSty}
                                                                        onClick={() => { addResource(itemTypesForResourcesValArr[i]) }}
                                                                    >
                                                                        {itemType}
                                                                    </div>
                                                                ))}
                                                            </div>}
                                                        />
                                                    </div>
                                                </th>
                                                <th className={classes.qtyTableSty} >Qty</th>
                                                <th className={classes.qtyWastageTableSty} >Qty <span style={{ fontSize: "9px", fontWeight: "400" }} >wastage</span> (%)</th>
                                                <th className={classes.qtyTotalTableSty} >Total Qty</th>
                                                <th className={classes.unitTableSty} >Unit</th>
                                                <th className={classes.rateTableSty} >Rate</th>
                                                <th className={classes.taxTableSty} >Tax</th>
                                                <th className={classes.rateTaxTableSty} >Rate <span style={{ fontSize: "9px", fontWeight: "400" }} >Including Tax</span></th>
                                                <th className={classes.totalTableSty} >Total Cost <span style={{ fontSize: "9px", fontWeight: "400" }} >Including Tax</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allItemsDetailsObjArr && allItemsDetailsObjArr.map((ItemDetailsObj, i) => (
                                                <React.Fragment key={i}>
                                                    {ItemDetailsObj?.name === "contractorAddOns" && ItemDetailsObj?.arr && ItemDetailsObj.arr.length > 0 ? (
                                                        <tr>
                                                            <td colspan="9" style={{ fontSize: "15px", fontWeight: "510", textAlign: "right", paddingRight: "10px" }}  >
                                                                Total ({totalAmountWithoutAdOnAlphabet.join(" + ")}) : {parseFloat(totalAmountWithoutAdOn || 0).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    <tr>
                                                        <th
                                                            colspan="8"
                                                            style={{
                                                                textAlign: "left",
                                                                fontSize: "15px",
                                                                fontWeight: "510",
                                                                paddingLeft: "5px"
                                                            }}
                                                        >{`${ItemDetailsObj?.alphabet}.${keyValuePair[ItemDetailsObj?.name]}`}</th>
                                                        <th style={{ paddingRight: "10px", textAlign: "right" }} >{ItemDetailsObj?.totalAmount}</th>
                                                    </tr>
                                                    {ItemDetailsObj && ItemDetailsObj.arr.map((resourceData, j) => {
                                                        if (ItemDetailsObj?.name === "contractorAddOns") {
                                                            return (
                                                                <tr className="resourceLine" key={j} >
                                                                    <td colspan="3"  >
                                                                        <div style={{ width: "100%", paddingTop: "12px" }} >
                                                                            <input
                                                                                value={resourceData?.name}
                                                                                onChange={(e) => {
                                                                                    let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                                    locContractorAddOnsArr[j].name = e.target.value;
                                                                                    setContractorAddOnsArr(locContractorAddOnsArr);
                                                                                }}
                                                                                placeholder="Enter item name"
                                                                                type="text"
                                                                                className={classes.textInputSty}
                                                                                style={{ width: "100%" }}
                                                                            />
                                                                            <div className={classes.tableErrMsg} >
                                                                                {resourceData?.name && resourceData.name.length > 1 ? null : showErrors ? "Name is required" : null}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td colspan="2" >
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            value={resourceData?.adOnType}
                                                                            options={["Tax", "Other"]}
                                                                            getOptionLabel={(option) => { return option || " "; }}
                                                                            getOptionSelected={(option) => {
                                                                                return option === resourceData?.adOnType
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    placeholder="select add on type"
                                                                                />
                                                                            )}
                                                                            onChange={(e, value) => {
                                                                                let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                                locContractorAddOnsArr[j].adOnType = value;
                                                                                setContractorAddOnsArr(locContractorAddOnsArr);
                                                                            }}
                                                                            fullWidth
                                                                            size="small"
                                                                        />
                                                                    </td>
                                                                    <td colspan="2"  >
                                                                        <Autocomplete
                                                                            freeSolo
                                                                            value={resourceData?.rateType}
                                                                            options={["Rate in Amount", "Rate in %"]}
                                                                            getOptionLabel={(option) => { return option || " "; }}
                                                                            getOptionSelected={(option) => {
                                                                                return option === resourceData?.rateType
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    placeholder="select type"
                                                                                />
                                                                            )}
                                                                            onChange={(e, value) => {
                                                                                let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                                locContractorAddOnsArr[j].rateType = value;
                                                                                if (value == "Rate in Amount") {
                                                                                    locContractorAddOnsArr[j].total = locContractorAddOnsArr[j]?.value || 0;
                                                                                } else {
                                                                                    let locTotal = parseFloat((parseFloat(totalAmountWithoutAdOn) * parseFloat(locContractorAddOnsArr[j]?.value || 0)) / 100);
                                                                                    locContractorAddOnsArr[j].total = locTotal;
                                                                                }
                                                                                setContractorAddOnsArr(locContractorAddOnsArr);
                                                                            }}
                                                                            fullWidth
                                                                            size="small"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={resourceData?.value}
                                                                            onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                let locContractorAddOnsArr = [...contractorAddOnsArr];
                                                                                locContractorAddOnsArr[j].value = value;
                                                                                if (locContractorAddOnsArr[j].rateType === "Rate in Amount") {
                                                                                    locContractorAddOnsArr[j].total = value;
                                                                                } else {
                                                                                    let locTotal = parseFloat((parseFloat(totalAmountWithoutAdOn) * parseFloat(value)) / 100);
                                                                                    locContractorAddOnsArr[j].total = locTotal;
                                                                                }
                                                                                setContractorAddOnsArr(locContractorAddOnsArr);
                                                                            }}
                                                                            placeholder="Enter value"
                                                                            type="number"
                                                                            className={classes.numberInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ paddingRight: "10px" }} >
                                                                        <div className={classes.totalDeleteCont} >
                                                                            <p>{parseFloat(resourceData?.total || 0).toFixed(2)}</p>
                                                                            <DeleteIcon onClick={() => { deleteResource(ItemDetailsObj?.name, j) }} className="resourceDeleteIcon" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return (
                                                                <tr className="resourceLine" key={j} >
                                                                    <td>
                                                                        <div style={{ width: "100%", paddingTop: "12px" }} >
                                                                            <input
                                                                                value={resourceData?.name}
                                                                                onChange={(e) => {
                                                                                    changeResourceName(ItemDetailsObj?.name, j, e.target.value)
                                                                                }}
                                                                                type="text"
                                                                                placeholder="Enter item name"
                                                                                className={classes.textInputSty}
                                                                                style={{ width: "100%" }}
                                                                            />
                                                                            <div className={classes.tableErrMsg} >
                                                                                {resourceData?.name && resourceData.name.length > 1 ? null : showErrors ? "Name is required" : null}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={resourceData?.qty}
                                                                            onChange={(e) => {
                                                                                changeResourceQty(ItemDetailsObj?.name, j, e.target.value)
                                                                            }}
                                                                            type="number"
                                                                            placeholder="Enter quantity"
                                                                            className={classes.numberInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={resourceData?.qtyWastage}
                                                                            onChange={(e) => {
                                                                                changeResourceQtyWastage(ItemDetailsObj?.name, j, e.target.value)
                                                                            }}
                                                                            type="number"
                                                                            placeholder="Enter quantity"
                                                                            className={classes.numberInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ paddingRight: "10px" }} >
                                                                        {parseFloat(resourceData?.totalQty || 0).toFixed(2)}
                                                                    </td>
                                                                    <td>
                                                                        <UnitAutocomplete
                                                                            fullWidth={true}
                                                                            value={resourceData?.unit}
                                                                            onChange={(e, value) => {
                                                                                changeResourceUnit(ItemDetailsObj?.name, j, value);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={resourceData?.price}
                                                                            onChange={(e) => {
                                                                                changeResourceRate(ItemDetailsObj?.name, j, e.target.value);
                                                                            }}
                                                                            type="number"
                                                                            placeholder="Enter rate"
                                                                            className={classes.numberInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={resourceData?.tax}
                                                                            onChange={(e) => {
                                                                                changeResourceTax(ItemDetailsObj?.name, j, e.target.value)
                                                                            }}
                                                                            type="number"
                                                                            placeholder="Enter tax"
                                                                            className={classes.numberInputSty}
                                                                            style={{ width: "100%" }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ paddingRight: "10px" }} >
                                                                        {parseFloat(resourceData?.rateIncludingTax || 0).toFixed(2)}
                                                                    </td>
                                                                    <td style={{ paddingRight: "10px" }} >
                                                                        <div className={classes.totalDeleteCont} >
                                                                            <p>{parseFloat(resourceData?.totalCostIncludingTax || 0).toFixed(2)}</p>
                                                                            <DeleteIcon onClick={() => { deleteResource(ItemDetailsObj?.name, j) }} className="resourceDeleteIcon" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </React.Fragment>))}
                                            {allItemsDetailsObjArr && allItemsDetailsObjArr.length > 0 ? (<>
                                                {contractorAddOnsArr && contractorAddOnsArr.length > 0 ? null : (
                                                    <tr>
                                                        <td colspan="9" style={{ fontSize: "15px", fontWeight: "500", textAlign: "right", paddingRight: "10px" }}  >
                                                            Total ({totalAmountWithoutAdOnAlphabet.join(" + ")}) : {parseFloat(totalAmountWithoutAdOn || 0).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td colspan="9" style={{ fontSize: "15px", fontWeight: "510", textAlign: "right", paddingRight: "10px" }}  >
                                                        Grand Total ({totalOfAllItemsAlphabet.join(" + ")}) : {parseFloat(totalOfAllItems || 0).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="9" style={{ padding: "0px 5px", fontSize: "15px", fontWeight: "500", textAlign: "left" }} >
                                                        <div style={{ textAlign: "left" }} >
                                                            Total Tax Component: {parseFloat(totalTaxAmount).toFixed(2)}
                                                        </div>
                                                        {forQty && (
                                                            <div style={{ textAlign: "right" }} >
                                                                Rates per No: {parseFloat((totalOfAllItems || 0) / (forQty || 1)).toFixed(2)}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>) : null}
                                        </tbody>
                                    </table>
                                )}
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Multiline"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={note}
                                    onChange={(e) => { setNote(e.target.value); }}
                                    defaultValue="Default Value"
                                    variant="outlined"
                                    size="small"
                                    style={{ marginTop: "20px" }}
                                />
                            </div>
                        </div>
                    </div>
                </>)}
            </div>}
        />
    );
};

export default EditWorkItem;