import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, Button } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import "./index.css"
import {
    addWorkItemAsChildOfWorkItem, createNewWorkItemWithExistingLibraryItem,
    createNewWorkItemWithNewLibrary, createWorkItemForGroup, deleteBudgetWorkItem, getSubheadDataAndWorkItems,
    reloadCategoriesAndSubcategoriesAndLibraries, updateSubheadById, updateWorkItemAndResources
} from "./api.call";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import BudgetSingleWorkItem from "./BudgetSingleWorkItem";
import BudgetCostAddWorkItem from "./BudgetCostAddWorkItem";
import { useDebounce } from "react-use";
import ApprovedAmountDetails from "./WorkitemDetailsDialog/ApprovedAmountDetails";
import GrossValueCertifiteDetails from "./WorkitemDetailsDialog/GrossValueCertifiteDetails";
import NetCertifitePayableDetails from "./WorkitemDetailsDialog/NetCertifitePayableDetails";
import PaidDetails from "./WorkitemDetailsDialog/PaidDetails";
import SubmittedAmountDetails from "./WorkitemDetailsDialog/SubmittedAmountDetails";
import BudgetCostSkeleton from "../styled/skeletons/BudgetCostSkeleton";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import LinkedTasksDetails from "./SubheadDetailsDialog/LinkedTasksDetails";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white"
    },
    headerSty: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
    },
    headerLeft: {
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "19px",
            fontWeight: "600"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 60px)",
        overflow: "auto",
        padding: "20px 15px 50px",
        position: "relative",
    },
    tableCont: {
        width: "100%",
    },
    subheadCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.palette.primary.main,
        padding: "5px 15px",
        borderTopRightRadius: "4px",
        borderTopLeftRadius: "4px",
    },
    leftSubhead: {
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600",
            color: "white"
        }
    },
    rigthSubhead: {
        display: "flex",
        alignItems: "center",
    },
    headAmountCont: {
        display: "flex",
        alignItems: "center",
        marginRight: "20px",
        "& h3": {
            fontSize: "13px",
            fontWeight: "400",
            color: "white",
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "510",
            color: "white",
            marginLeft: "3px"
        },
        "& span": {
            fontSize: "13px",
            fontWeight: "400",
            margin: "0px 5px",
            color: "white",
        }
    },
    tableSty: {
        width: "2940px",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            textAlign: "right",
            paddingRight: "10px"
        }
    },
    titleBtnCont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "15px",
        "& p": {
            fontSize: "13px",
            fontWeight: "500",
        },
        "& .MuiButton-root:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    btnCont: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        padding: "4px",
        borderRadius: "10px",
        marginLeft: "10px"
    },
    itemTitleSty: {
        width: "450px"
    },
    optionsCont: {
        padding: "5px"
    },
    singleOption: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        "& img": {
            width: "20px",
            height: "auto",
            marginRight: "10px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "&:hover": {
            backgroundColor: "#e2e2e2"
        }
    },
    width200: {
        width: "200px"
    },
    width100: {
        width: "100px"
    },
    width140: {
        width: "140px"
    },
    width150: {
        width: "150px"
    },
    overflowCont: {
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    overlayLoader: {
        top: "60px",
        left: "7rem",
        width: "calc(100vw - 7rem)",
        height: "calc(100vh - 60px)",
        padding: "25px",
        zIndex: "99",
        position: "Fixed",
        backgroundColor: "white",
        [theme.breakpoints.only("xs")]: {
            left: "0rem",
            width: "100vw",
        }
    },
    bottomBarForDeleteShow: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "0px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    bottomBarForDeleteHide: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "-80px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    selectText: {
        fontSize: "14px",
        color: "gray"
    },
    bottomRightSide: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            border: "1px solid black"
        },
        "& p": {
            margin: "0px 10px"
        }
    },
    iconText: {
        fontSize: "15px",
        marginLeft: "5px"
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
}));

const BudgetCostWorkItemEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const bodyRef = useRef(null);

    const { subheadId } = useParams();
    const [subheadData, setSubheadData] = useState(null)
    const [workitems, setWorkItems] = useState([])
    const [hierarchyArr, setHierarchyArr] = useState([])
    const [hierarchyArrWithTotal, setHierarchyArrWithTotal] = useState([])
    const [isCollapseChild, setCollapseChild] = useState(false)
    const [openItemAddDialog, setOpenItemAddDialog] = useState(false)
    //for item add
    const [libraryCategoryDictionary, setLibraryCategoryDictionary] = useState(null)
    const [totalAmountForSubHead, setTotalAmountForSubHead] = useState(0);
    const [totalTaxForSubHead, setTotalTaxForSubHead] = useState(0);
    const [totalAmountWithOutTaxForSubhead, setTotalAmountWithOutTaxForSubhead] = useState(0);
    const [openPaidDetails, setOpenPaidDetails] = useState(false)
    const [openNetCertifitePayableDetails, setOpenNetCertifitePayableDetails] = useState(false)
    const [openGrossValueCertifiteDetails, setOpenGrossValueCertifiteDetails] = useState(false)
    const [openApprovedAmountDetails, setOpenApprovedAmountDetails] = useState(false)
    const [openSubmittedAmountDetails, setOpenSubmittedAmountDetails] = useState(false)
    const [selectedWorkitemData, setSelectedWorkitemData] = useState(false)
    const [parentChildrenMap, setParentChildrenMap] = useState({})
    const [idWorkitemMap, setIdWorkitemMap] = useState({})
    const [loader, setLoader] = useState(true)
    const [normalLoader, setNormalLoader] = useState(false)
    const [workitemDeleteOpen, setWorkitemDeleteOpen] = useState(false)
    const [allSelectedIds, setAllSelectedIds] = useState([])
    const [needToMakeDisable, setNeedToMakeDisable] = useState(false)
    const [taskOpenData, setTaskOpenData] = useState({
        open: false,
        taskIds: []
    })

    useEffect(() => {
        bodyRef.current.scrollTo(0, 0);
    }, [])

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    //to get initial data and restructher data-------------------------start
    const getInitialData = async (isIntialCall) => {
        if (isIntialCall !== null) {
            if (isIntialCall) {
                setLoader(true)
            } else {
                setNormalLoader(true)
            }
        }
        await getSubheadDataAndWorkItems({
            subheadId: subheadId
        })
            .then(async (data) => {
                console.log(data)
                const allWorkItemsArr = data?.allWorkItems || []
                let objParentChildren = {}
                let idDataMap = {}
                let parentChildMap = new Map();
                allWorkItemsArr.map((workitem, i) => {
                    idDataMap[workitem?._id] = workitem
                    workitem.ancestors.forEach(parentId => {
                        if (!parentChildMap.has(parentId)) {
                            parentChildMap.set(parentId, []);
                        }
                        parentChildMap.get(parentId).push(workitem._id);
                    });
                })
                for (const [key, value] of parentChildMap) {
                    objParentChildren[key] = value
                }
                setParentChildrenMap(objParentChildren)
                setIdWorkitemMap(idDataMap)
                setSubheadData(data?.subheadData || null);
                setWorkItems(allWorkItemsArr);
                if (allWorkItemsArr && allWorkItemsArr.length > 0) {
                    await timeout(3000)
                }
                if (isIntialCall !== null) {
                    if (isIntialCall) {
                        setLoader(false)
                        bodyRef.current.scrollTo(0, 0);
                    } else {
                        setNormalLoader(false)
                    }
                }
                const locProfileId = data?.subheadData?.profile?._id
                await getLibrariesAndCategories(locProfileId);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const buildHierarchy = (items, parent = null) => {
        const result = [];
        for (const item of items) {
            if (item.parent == parent) {
                const children = buildHierarchy(items, item._id);
                if (children.length > 0) {
                    item.children = children;
                }

                if (item.numberOfChildren === 0) {
                    result.push({
                        ...item,
                        totalAmount: parseFloat(parseFloat(item?.qty || 0) * parseFloat(item?.ratePerUnitWithTax || 0)).toFixed(2),
                        totalGST: parseFloat(parseFloat(item?.qty || 0) * parseFloat(item?.taxPerUnit || 0)).toFixed(2)
                    })
                } else {
                    let childTaskTotalCount = 0;
                    children.map((child) => {
                        childTaskTotalCount = childTaskTotalCount + parseInt(child?.numberOfTasks || 0)
                    })
                    let childBillListTotalCount = 0;
                    children.map((child) => {
                        childBillListTotalCount = childBillListTotalCount + parseInt(child?.numberOfBillLists || 0)
                    })
                    result.push({
                        ...item,
                        numberOfTasks: parseInt(item?.numberOfTasks || 0) + parseInt(childTaskTotalCount || 0),
                        numberOfBillLists: parseInt(item?.numberOfBillLists || 0) + parseInt(childBillListTotalCount || 0),
                    });
                }
            }
        }
        return result;
    }

    useEffect(() => {
        const parentChildArray = buildHierarchy(workitems)
        setHierarchyArr(parentChildArray)
    }, [workitems])
    //to get initial data and restructher data-------------------------end

    //to get categories --------------------------------start
    const getLibrariesAndCategories = async (givenProfileId) => {
        if (givenProfileId) {
            await reloadCategoriesAndSubcategoriesAndLibraries({
                projectProfileId: givenProfileId
            })
                .then((data) => {
                    if (data) {
                        let libraryCategorySubcategoryArr = []
                        data && data.map((libraryObj) => {
                            if (libraryObj?.public) {
                                libraryCategorySubcategoryArr.push({
                                    parentTitle: "Public Libraries",
                                    ...libraryObj
                                })
                            } else {
                                libraryCategorySubcategoryArr.push({
                                    parentTitle: "Private Libraries",
                                    ...libraryObj
                                })
                            }
                        })
                        console.log(libraryCategorySubcategoryArr)
                        setLibraryCategoryDictionary(libraryCategorySubcategoryArr)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        getInitialData(true)
    }, [])
    //to get categories --------------------------------end

    //it change numbers of workitem
    const createWorkItem = async (isNewLibrary, dataObj, isParentGroupItem, parentData, selectedLibrary) => {
        let reqData = {
            ...dataObj,
            projectProfileId: subheadData?.profile?._id,
            userId: user?._id,
            subworkhead: subheadData?._id,
            projectName: subheadData?.profile?.parent?.displayName,
            parentAncestor: parentData?.ancestors || [],
            workHead: subheadData?.budgetCostHead,
            budgetCostId: subheadData.budgetCost,
            selectedLibrary
        }

        if (isParentGroupItem === true || parentData === null) {
            if (isNewLibrary) {
                //creating totally a new workitem with default library
                await createNewWorkItemWithNewLibrary(reqData)
                    .then(async (data) => {
                        const newItem = data?.curItem
                        const parentItem = data?.parentItem
                        let arr = []
                        workitems.map((workItem) => {
                            if (parentItem && parentItem?._id === workItem?._id) {
                                arr.push(parentItem)
                            } else {
                                arr.push(workItem)
                            }
                        })
                        arr.push(newItem)
                        setWorkItems(arr)
                        await getInitialData(null);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                //create workItem with exisiting library and workItem
                await createNewWorkItemWithExistingLibraryItem(reqData)
                    .then(async (data) => {
                        const newItem = data?.curItem
                        const parentItem = data?.parentItem
                        let arr = []
                        workitems.map((workItem) => {
                            if (parentItem && parentItem?._id === workItem?._id) {
                                arr.push(parentItem)
                            } else {
                                arr.push(workItem)
                            }
                        })
                        arr.push(newItem)
                        setWorkItems(arr)
                        await getInitialData(null);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        } else {
            reqData = {
                libraryId: parentData?.codeLibrary,
                libraryName: parentData?.libraryLable,
                parentId: parentData?._id,
                numberOfChildren: parentData?.numberOfChildren,
                subworkhead: subheadData?._id,
                budgetCostId: subheadData.budgetCost,
                projectProfileId: subheadData?.profile?._id,
                userId: user?._id,
                workItemTitle: dataObj?.itemName,
                parentAncestor: parentData?.ancestors || [],
                workHead: subheadData?.budgetCostHead,
                isRateEditable: dataObj?.isRateEditable,
                workItemCategory: parentData?.category,
                workItemSubcategory: parentData?.subCategory,
                workItemResourceType: parentData?.resourceType,
                workItemRate: parentData?.rate,
                workItemRateType: parentData?.rateType,
                workItemUnit: parentData?.unit,
                workItemQty: parentData?.forQty,
                ratePerUnitWithoutTax: parentData?.ratePerUnit,
                ratePerUnit: parentData?.ratePerUnitWithTax,
                totalTax: parentData?.taxPerUnit,
                selectedLibrary
            }
            //add new work item as "Direct Rate" means there will not be any "resources" or "adOns"
            await addWorkItemAsChildOfWorkItem(reqData)
                .then(async (data) => {
                    const newItem = data?.curItem
                    const parentItem = data?.parentItem
                    let arr = []
                    workitems.map((workItem) => {
                        if (parentItem && parentItem?._id === workItem?._id) {
                            arr.push(parentItem)
                        } else {
                            arr.push(workItem)
                        }
                    })
                    arr.push(newItem)
                    setWorkItems(arr)
                    await getInitialData(null);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    //it change numbers of workitem
    const createGroupItem = async (parentId = null, numberOfChildren = 0, parentData) => {
        let newObj = {
            userId: user?._id,
            subworkhead: subheadData?._id,
            numberOfChildren: numberOfChildren,
            parentAncestor: parentData?.ancestors || [],
            workHead: subheadData?.budgetCostHead,
            budgetCostId: subheadData.budgetCost
        }
        if (parentId) {
            newObj.parentId = parentId
        }
        if (subheadData?.profile?._id) {
            newObj.projectProfileId = subheadData?.profile?._id;
        }

        await createWorkItemForGroup(newObj)
            .then(async (data) => {
                const newItem = data?.curItem
                const parentItem = data?.parentItem
                let arr = []
                workitems.map((workItem) => {
                    if (parentItem && parentItem?._id === workItem?._id) {
                        arr.push(parentItem)
                    } else {
                        arr.push(workItem)
                    }
                })
                arr.push(newItem)
                setWorkItems(arr)
                await getInitialData();
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //to calculate amounts----------------------------------------------------start
    function calculateTotals(node) {
        if (node?.children && node.children.length > 0) {
            node.children.forEach(child => calculateTotals(child));
            node.qty = node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.qty || 0), 0);
            node.totalAmount = node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalAmount || 0), 0);
            node.totalGST = node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalGST || 0), 0);
            node.totalApprovedAmount = node.totalApprovedAmount + node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalApprovedAmount || 0), 0)
            node.totalSubmittedAmount = node.totalSubmittedAmount + node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalSubmittedAmount || 0), 0)
            node.totalTxInitialAmount = node.totalTxInitialAmount + node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalTxInitialAmount || 0), 0)
            node.totalPaidAmount = node.totalPaidAmount + node.children.reduce((acc, child) => parseFloat(acc || 0) + parseFloat(child?.totalPaidAmount || 0), 0)
            node.totalLocOutstanding = node.totalTxInitialAmount - node.totalPaidAmount
            node.totalLocBudgetBalance = node.totalAmount - node.totalApprovedAmount
        } else {
            node.totalAmount = parseFloat(node?.qty || 0) * parseFloat(node.ratePerUnitWithTax || 0);
            node.totalLocOutstanding = node.totalTxInitialAmount - node.totalPaidAmount
            node.totalLocBudgetBalance = node.totalAmount - node.totalApprovedAmount
        }
    }

    useEffect(() => {
        let calculatedArray = JSON.parse(JSON.stringify(hierarchyArr));
        calculatedArray.forEach(rootNode => calculateTotals(rootNode));
        let locTotalAmountForSubHead = calculatedArray.reduce((acc, item) => parseFloat(acc || 0) + parseFloat(item?.totalAmount || 0), 0)
        let locTotalTaxForSubHead = calculatedArray.reduce((acc, item) => parseFloat(acc || 0) + parseFloat(item?.totalGST || 0), 0)
        let locTotalAmountWithOutTaxForSubhead = (parseFloat(locTotalAmountForSubHead || 0) - parseFloat(locTotalTaxForSubHead || 0)).toFixed(2);

        setTotalAmountForSubHead(locTotalAmountForSubHead)
        setTotalTaxForSubHead(locTotalTaxForSubHead)
        setTotalAmountWithOutTaxForSubhead(locTotalAmountWithOutTaxForSubhead)
        setHierarchyArrWithTotal(calculatedArray)
    }, [hierarchyArr])

    useDebounce(() => {
        if (subheadData?._id) {
            updateSubheadById({
                _id: subheadData._id,
                totalEstimatedCost: totalAmountWithOutTaxForSubhead,
                totalEstimatedGST: totalTaxForSubHead,
                totalAllocated: parseFloat(totalAmountWithOutTaxForSubhead || 0) + parseFloat(totalTaxForSubHead || 0)
            })
                .then((data) => {
                    console.log("data")
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, 1500, [totalAmountForSubHead, totalTaxForSubHead, totalAmountWithOutTaxForSubhead])
    //to calculate amounts----------------------------------------------------end

    //check uncheck handler --------------------------------start
    const unSelectHandle = (selectedIds) => {
        let arr = [...selectedIds]
        let flag = false;
        Object.keys(parentChildrenMap).forEach(key => {
            let parentId = key;
            let children = parentChildrenMap[key] ? parentChildrenMap[key] : [];
            let selected = children.filter((id) => selectedIds.includes(id))
            if (selectedIds.includes(parentId) && children.length > selected.length && children.length > 0 && selectedIds.includes(parentId)) {
                arr = arr.filter((id) => id != parentId)
                flag = true;
            }
        });
        if (flag) {
            arr = unSelectHandle(arr)
        }
        return arr
    }

    const handleCheck = (curId, isChecked) => {
        let arr = []
        let objMap = {}
        let allChildren = parentChildrenMap[curId] ? parentChildrenMap[curId] : []
        let allIds = [...allChildren, curId]
        if (isChecked) {
            const setArr = new Set([...allIds, ...allSelectedIds])
            arr = [...setArr]
        } else {
            arr = allSelectedIds.filter(item => !allIds.includes(item));
            arr = unSelectHandle(arr)
        }
        setAllSelectedIds(arr)
    }
    //check uncheck handler --------------------------------end

    //it change numbers of workitem
    const deleteWotkItems = async () => {
        let bulkUpdateObjArr = []
        let deletableOfferings = []
        Object.keys(parentChildrenMap).forEach(id => {
            if (!allSelectedIds.includes(id)) {
                const dirctChildren = idWorkitemMap[id].children.map((item) => item?._id)
                const undeletableChildren = dirctChildren.filter((id) => !allSelectedIds.includes(id))
                if (dirctChildren.length !== undeletableChildren.length) {
                    bulkUpdateObjArr.push({
                        updateOne: {
                            filter: { _id: id },
                            update: {
                                numberOfChildren: undeletableChildren.length,
                                items: undeletableChildren,
                            }
                        }
                    })
                }
            }
        })

        allSelectedIds.map((id) => {
            const data = idWorkitemMap[id]?.resources || []
            const offeringIds = data.map((item) => { return item?.resource?._id })
            deletableOfferings = [...deletableOfferings, ...offeringIds]
        })

        await deleteBudgetWorkItem({
            bulkUpdateObjArr,
            deleteAbleIds: allSelectedIds,
            deletableOfferings
        })
            .then(async (data) => {
                await getInitialData(false)
                setWorkitemDeleteOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //approval handle--------------------------------------------------------------------------------------
    useEffect(() => {
        let locActiveStage = subheadData?.budgetCost?.approvalRequest?.activeStage || null
        let canEdit = false
        if (locActiveStage?.approvalType === "anybody") {
            canEdit = true
        } else {
            let approvers = locActiveStage?.approvers || [];
            for (let i = 0; i < approvers.length; i++) {
                if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
                    canEdit = true;
                    break;
                }
            }
        }
        if (locActiveStage?.canEditMainData && canEdit) {
            setNeedToMakeDisable(false)
        } else {
            setNeedToMakeDisable(true)
        }
    }, [subheadData?.budgetCost?.approvalRequest?.activeStage])

    return (<div className={classes.mainCont} >
        <div className={classes.headerSty} >
            <div className={classes.headerLeft} >
                <IconButton onClick={() => { history.goBack() }} >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <h3>
                    Detailed Estimate
                </h3>
            </div>
            <div></div>
        </div>
        <div className={classes.bodyCont} ref={bodyRef} >
            {loader && (<div className={classes.overlayLoader} >
                <BudgetCostSkeleton isSubHead={true} headerHeight={50} />
            </div>)}
            <Paper className={classes.tableCont} elevation={2} >
                <div className={classes.subheadCont} >
                    <div className={classes.leftSubhead} >
                        <h3>{subheadData?.name}</h3>
                    </div>
                    <div className={classes.rigthSubhead} >
                        <div className={classes.headAmountCont} >
                            {parseFloat(subheadData?.totalBudgetCost || 0) > 0 || totalAmountForSubHead > 0 ? (<>
                                {subheadData?.totalBudgetCost > 0 ? (<>
                                    <h3>Budgeted Cost :</h3>
                                    <p>{parseFloat(subheadData?.totalBudgetCost).toFixed(2)}</p>
                                </>) : null}
                                {subheadData?.totalBudgetCost > 0 && totalAmountForSubHead > 0 ? (
                                    <span>/</span>
                                ) : null}
                                <h3>Estimated Cost :</h3>
                                <p style={totalAmountForSubHead < parseFloat(subheadData?.totalBudgetCost || 0) ? { color: "white" } : { color: "#b21a1a" }} >{parseFloat(totalAmountForSubHead).toFixed(2)}</p>
                            </>) : null}
                        </div>
                        {/* <IconButton>
                            <LockOutlinedIcon style={{ color: "white" }} />
                        </IconButton>
                        <IconButton>
                            <RestoreOutlinedIcon style={{ color: "white" }} />
                        </IconButton>
                        <IconButton>
                            <PrintOutlinedIcon style={{ color: "white" }} />
                        </IconButton>
                        <IconButton>
                            <ScreenShareOutlinedIcon style={{ color: "white" }} />
                        </IconButton> */}
                    </div>
                </div>
                <div className={classes.overflowCont} >
                    <table className={classes.tableSty} >
                        <tr>
                            <th colspan="3" >Basic Details</th>
                            <th rowspan="2" className={classes.width200} >Linked Items</th>
                            <th colspan="5" >Allocated</th>
                            <th colspan="2" >Contracted</th>
                            <th colspan="3" >Payments</th>
                            <th rowspan="2" className={classes.width200} >Budget Balance </th>
                        </tr>
                        <tr>
                            <th className={classes.itemTitleSty} >
                                <div className={classes.titleBtnCont} >
                                    <p>Item Group / Work Item</p>
                                    {!needToMakeDisable && (<>
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={
                                                <Button className={classes.btnCont} >
                                                    <AddIcon style={{ marginRight: "5px", fontSize: "15px", color: "white" }} />
                                                    <ExpandMoreIcon style={{ fontSize: "15px", color: "white" }} />
                                                </Button>
                                            }
                                            showContent={<div className={classes.optionsCont} >
                                                <div className={classes.singleOption} onClick={() => { createGroupItem(null, 0, null) }} >
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/item-group-icon.svg?alt=media" />
                                                    <p>Item Group</p>
                                                </div>
                                                <div className={classes.singleOption} onClick={() => { setOpenItemAddDialog(true) }} >
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/reallist-f5ea3.appspot.com/o/work-item-icon.svg?alt=media" />
                                                    <p>Work Item</p>
                                                </div>
                                            </div>}
                                        />
                                        <BudgetCostAddWorkItem
                                            openItemAddDialog={openItemAddDialog}
                                            setOpenItemAddDialog={setOpenItemAddDialog}
                                            createWorkItem={createWorkItem}
                                            libraryCategoryDictionary={libraryCategoryDictionary}
                                            setLibraryCategoryDictionary={setLibraryCategoryDictionary}
                                            projectProfileId={subheadData?.profile?._id}
                                            curWorkItem={null}
                                        />
                                    </>)}
                                </div>
                            </th>
                            <th className={classes.width140} >Item Type</th>
                            <th className={classes.width100} >Unit</th>
                            <th className={classes.width100} >Quantity</th>
                            <th className={classes.width200} >Base Rate/Unit</th>
                            <th className={classes.width200} >Base Amount</th>
                            <th className={classes.width150} >Total Tax</th>
                            <th className={classes.width200} >Gross Amount</th>
                            <th className={classes.width200} >Submitted Amount</th>
                            <th className={classes.width200} >Approved Amount</th>
                            <th className={classes.width200} >Gross Value Certifite</th>
                            <th className={classes.width200} >Paid</th>
                            <th className={classes.width200} >Outstanding</th>
                        </tr>
                        {hierarchyArrWithTotal && hierarchyArrWithTotal.map((workitemData) => (
                            <BudgetSingleWorkItem
                                key={workitemData._id}
                                needToMakeDisable={needToMakeDisable}
                                workitemData={workitemData}
                                createGroupItem={createGroupItem}
                                createWorkItem={createWorkItem}
                                paddingLeft={15}
                                idWorkitemMap={idWorkitemMap}
                                setTaskOpenData={setTaskOpenData}
                                isCollapseChild={isCollapseChild}
                                setCollapseChild={setCollapseChild}
                                workitems={workitems}
                                parentChildrenMap={parentChildrenMap}
                                subheadData={subheadData}
                                setWorkItems={setWorkItems}
                                libraryCategoryDictionary={libraryCategoryDictionary}
                                setLibraryCategoryDictionary={setLibraryCategoryDictionary}
                                setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
                                setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
                                setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
                                setOpenPaidDetails={setOpenPaidDetails}
                                setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
                                setSelectedWorkitemData={setSelectedWorkitemData}
                                handleCheck={handleCheck}
                                allSelectedIds={allSelectedIds}
                            />
                        ))}
                    </table>
                </div>
            </Paper>
            <div className={allSelectedIds && allSelectedIds.length > 0 ? classes.bottomBarForDeleteShow : classes.bottomBarForDeleteHide} >
                <div className={classes.selectText} >
                    {`${allSelectedIds.length} Selected`}
                </div>
                <div className={classes.bottomRightSide} >
                    <IconButton onClick={() => { setWorkitemDeleteOpen(true) }} >
                        <DeleteIcon />
                        <p className={classes.iconText} >Delete</p>
                    </IconButton>

                    <IconButton onClick={() => { setAllSelectedIds([]) }} >
                        <CloseIcon />
                        <p className={classes.iconText} >Cancel</p>
                    </IconButton>
                </div>
            </div>
        </div>
        <SubmittedAmountDetails
            openSubmittedAmountDetails={openSubmittedAmountDetails}
            setOpenSubmittedAmountDetails={setOpenSubmittedAmountDetails}
            selectedWorkitemData={selectedWorkitemData}
            parentChildrenMap={parentChildrenMap}
            getInitialData={getInitialData}
            needToMakeDisable={needToMakeDisable}
        />
        <ApprovedAmountDetails
            openApprovedAmountDetails={openApprovedAmountDetails}
            setOpenApprovedAmountDetails={setOpenApprovedAmountDetails}
            selectedWorkitemData={selectedWorkitemData}
            parentChildrenMap={parentChildrenMap}
            getInitialData={getInitialData}
            needToMakeDisable={needToMakeDisable}
        />
        <GrossValueCertifiteDetails
            openGrossValueCertifiteDetails={openGrossValueCertifiteDetails}
            setOpenGrossValueCertifiteDetails={setOpenGrossValueCertifiteDetails}
            selectedWorkitemData={selectedWorkitemData}
            parentChildrenMap={parentChildrenMap}
            getInitialData={getInitialData}
            needToMakeDisable={needToMakeDisable}
        />
        <PaidDetails
            openPaidDetails={openPaidDetails}
            setOpenPaidDetails={setOpenPaidDetails}
            selectedWorkitemData={selectedWorkitemData}
            parentChildrenMap={parentChildrenMap}
            getInitialData={getInitialData}
            needToMakeDisable={needToMakeDisable}
        />
        <NetCertifitePayableDetails
            openNetCertifitePayableDetails={openNetCertifitePayableDetails}
            setOpenNetCertifitePayableDetails={setOpenNetCertifitePayableDetails}
            selectedWorkitemData={selectedWorkitemData}
            parentChildrenMap={parentChildrenMap}
            getInitialData={getInitialData}
            needToMakeDisable={needToMakeDisable}
        />
        <LinkedTasksDetails
            openLinkedTasksDetails={taskOpenData?.open}
            setOpenLinkedTasksDetails={(boolValue) => {
                setTaskOpenData({
                    ...taskOpenData,
                    open: boolValue
                })
            }}
            taskIds={taskOpenData?.taskIds}
        />
        <DeleteConfirmBox
            open={workitemDeleteOpen}
            setOpen={setWorkitemDeleteOpen}
            loading={normalLoader}
            pageTitle={`Delete Workitem`}
            cantent={
                <div className={classes.deleteConfirm} >
                    <ReportProblemIcon />
                    <h3>Are you sure?</h3>
                    <p>You won't be able to revert this!</p>
                </div>
            }
            cancleConfirmDelete={() => {
                setWorkitemDeleteOpen(false)
            }}
            confirmDelete={() => {
                deleteWotkItems()
            }}
        />
    </div>);
};

export default BudgetCostWorkItemEdit;